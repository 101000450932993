.is-not-prac-warn-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--font-size-large);
    align-items: center;
}

.is-not-prac-warn-message-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /*justify-content: space-between;*/
    align-items: center;
    background-color: var(--color-light-ocra);
    color: var(--color-xdark-ocra);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-medium);
    line-height: var(--font-size-large);
    padding: var(--font-size-xsmall);
    margin-top: var(--font-size-medium);
    border-radius: var(--border-radius-small);
}

.is-not-prac-warn-icon {
    height: var(--font-size-large);
    width: var(--font-size-large);
    flex: 0 0 auto;
    margin-right: var(--font-size-xsmall);
}
