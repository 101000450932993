/* search results */
.syndrome-line-search {
  list-style: none;
  margin: 0;
  color: var(--color-blue);
  padding: 0;
  border: var(--border) transparent;
  border-bottom: var(--border) var(--color-xxlight-grey);
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.syndrome-line-search > div {
  padding: 0.8em 1em;
  display: flex;
  gap: 1rem;
}
.syndrome-line-search .highlight {
  font-weight: bold;
  border-bottom: var(--border) var(--color-light-grey);
}
.syndrome-line-search .syndrome-name {
  flex-grow: 2;
}
.syndrome-line-search .syndrome-cta {
  color: var(--color-xlight-grey);
  margin-right: 0.5em;
  font-size: 0.8em;
  white-space: nowrap;
}



/* everywhere else */

.syndrome-line {
  border-top: var(--border) var(--color-xxlight-grey);
  position: relative;
  padding: 0.5rem 1rem;
}
.syndrome-line.compact-mode {
  padding-top: 0;
  padding-bottom: 0;
}
.syndrome-line:last-of-type {
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.syndrome-line:hover {
  color: var(--color-light-blue);
}
.syndrome-line .syndrome-line-heading {
  padding: 1rem 0;
  display: flex;
  cursor: pointer;
}
.syndrome-line .syndrome-line-name {
  flex-grow: 2;
  color: var(--color-blue);
}
.syndrome-line .syndrome-line-name small {
  font-size: var(--font-size-small);
  color: var(--color-grey);
}
.syndrome-line:hover .syndrome-line-name {
  color: var(--color-black);
}
.syndrome-line.is-discarded .syndrome-line-name {
  text-decoration: line-through;
}
.syndrome-line .syndrome-line-score {
  white-space: nowrap;
  font-size: var(--font-size-small);
  color: var(--color-grey);
  padding: 0;
  min-width: 3rem;
}
.syndrome-line .syndrome-line-cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-light-grey);
  border: var(--border) var(--color-xxlight-grey);
  border-radius: 3rem;
  padding: 0;
  width: 1.4rem;
  min-width: 1.4rem;
  height: 1.4rem;
  white-space: nowrap;
  position: relative;
  transition: width 0.3s ease-in-out;
  text-align:center;
  overflow: hidden;  
  margin-left: 0.8rem;
}
.syndrome-line .syndrome-line-heading:hover .syndrome-line-cta {
  color: var(--color-black);
  border-color: var(--color-black);
}

.syndrome-line .syndrome-line-details {
  display: flex;
  padding: 0 0 0.6rem 2rem;
  margin-top: -0.5rem;
  align-items: center;
}
.syndrome-line .syndrome-line-details > label {
  font-size: var(--font-size-small);
  color: var(--color-light-grey);
  padding: 0.4rem 0;
  margin-right: 0.8rem;
  white-space: nowrap;
  cursor: default;
}
.syndrome-line .syndrome-line-malformations {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
}
.syndrome-line .syndrome-line-malformation {
  font-size: var(--font-size-small);
  background: var(--color-white);
  padding: 0.2rem 1rem;
  margin: 0.2rem;
  white-space: nowrap;
  cursor: pointer;
  flex-grow: 2;
  max-width: 42%;
  min-width: 42%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.syndrome-line .syndrome-line-malformation:hover {
  color: var(--color-blue);
}
.syndrome-line .syndrome-line-icon {
  display: inline-block;
  font-size: var(--font-size-small);
  vertical-align: baseline;
  line-height: var(--font-size-small);
  margin-right: 0.8em;
  border: var(--border);
  border-radius: var(--border-radius-large);
  padding: 0.4em;
  text-align: center;
  color: var(--color-light-grey);
}
.syndrome-line .syndrome-line-icon:hover {
  color: var(--color-black);
  border-color: var(--color-black);
}

.syndrome-line.is-hidden {
  animation: syndrome-line-hide 2s forwards;
  pointer-events: none;
}
@keyframes syndrome-line-hide {
  0% { opacity: 1 }
  100% { opacity: 0 }
}