.examination-sharing-indicator .icon {
  width: 1.6em;
  height: 1.6em;
  opacity: 0.3;
  margin-right: 0.5em;
}
.examination-sharing-indicator.slim .icon {
    margin-right: 0;
}

.examination-sharing-indicator .icon svg {
  width: 100%;
  height: 100%;
}

.examination-sharing-indicator.active .icon {
  color: var(--color-ocra);
  opacity: 1;
}

.examination-sharing-indicator.active .icon svg {
  filter: drop-shadow(0px 0px 10px var(--color-ocra));
}

.examination-sharing-history-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: var(--color-white);
}

.examination-sharing-history-popup-container .popup-close {
  z-index: 10001;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: var(--font-size-xlarge);
  cursor: pointer;
}

.examination-sharing-history-popup-container .history {
  width: 70vw;
}

.examination-sharing-history-popup-container .history .row {
  padding: 0.8rem 0;
  align-items: center;
}

.examination-sharing-history-popup-container .history .row div {
  padding: 0;
}

.examination-sharing-history-popup-container .history h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.examination-sharing-history-popup-container .history .headers {
  font-size: var(--font-size-medium);
  font-weight: bold;
}

.examination-sharing-history-popup-container .history .data {
  border-top: 1px solid var(--color-common-light-blue);
}

.examination-sharing-history-popup-container .history .data .delete {
  cursor: pointer;
}

.examination-sharing-history-popup-container .history .data .last {
  text-align: right;
}
