.patients-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.patients-page-container .patients-container {
  overflow-y: scroll;
  height: calc(100% - 4rem);
}

