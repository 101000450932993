.patients-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
}

.patients-container h1 {
  font-size: var(--font-size-large);
  border-bottom: var(--border) var(--color-grey);
  padding-bottom: .4rem;
  margin-bottom: .7rem;
}
.theme-dark .patients-container h1 {
  border-bottom: var(--border) var(--color-xxlight-grey);
}

/* drafts */
.patients-container .patients-drafts {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.patients-container .patients-new-exam-button {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.patients-container div.patients-new-exam-button {
  border-radius: var(--border-radius-small);
  background: var(--color-xxxlight-grey);
  padding:3rem 1rem;
}
.patients-container .patients-new-exam-button:hover {
  color: var(--color-black);
  background: var(--color-xlight-blue);
}
.patients-container .patients-new-exam-button .icon {
  font-size: var(--font-size-xlarge);
  border: var(--border-thick);
  border-radius: var(--border-radius-large);
  width: 1.2em;
  height: 1.2em;
  line-height: 1.1em;
  margin-bottom: .3rem;
}
.patients-container .patients-new-exam-info {
  text-align: center;
  color: var(--color-xlight-grey);
  font-size: var(--font-size-small);
  margin-bottom: 2rem;
}

.patients-container .patients-searchbar {
  margin: .5rem 0 0 0;
}

.patients-container .patients-list-empty {
  text-align: center;
  color: var(--color-xlight-grey);
  font-size: var(--font-size-small);
}

.patients-container .patients-list {
  padding: 0 .5rem;
}
.patients-container .patients-list .row {
  padding:.5rem;
  border-bottom: var(--border) var(--color-grey);
  cursor:pointer;
}
.theme-dark .patients-container .patients-list .row {
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.patients-container .patients-list .row:hover {
  color: var(--color-black);
  background: var(--color-xxlight-grey);
}
.patients-container .patients-list .row .icon {
  transition: transform .3s ease-in-out;
}
.patients-container .patients-list .row:hover .icon {
  transform: translateX(.2em);
}
.patients-container .patients-list .row:last-of-type {
  border-bottom: 0;
}

.patients-container .patients-list .cta {
  font-size: var(--font-size-small);
  text-align: right;
}
.patients-container .patients-list .cta button {
  width: 100%;
}
.patients-container .patients-list .dob {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-light-grey);
}