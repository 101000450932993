.preferences-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
}

.preferences-container .gapped {
  display: flex;
  gap: 1rem;
}

.preferences-container .error {
  color: var(--color-red);
}

.preferences-container .success {
  color: var(--color-green);
}

.preferences-align-content {
  display: flex;
  align-items: center;
}

.preferences-space-around {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--font-size-large);
}

.prefrences-cert-id-wrapper {
  flex: 1;
}

.anonymized-images-note {
  color: var(--color-light-grey);
  font-style: italic;
}
