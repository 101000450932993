.exam-completed-banner {
  padding: 1rem 1rem 1rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-xxxlight-grey);
  border-bottom: var(--border) var(--color-xxlight-grey);
  font-size: var(--font-size-small);
}
.exam-completed-banner h2 {
  margin: 0;
}
.exam-completed-banner-stats {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.exam-completed-banner-stats .exam-completed-banner-stat {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
  cursor: default;
}
.exam-completed-banner-stats .exam-completed-banner-stat:not(.unusual) label {
  cursor: default;
}
.exam-completed-banner-stats .exam-completed-banner-stat b {
  font-size: var(--font-size-small);
}
.exam-completed-banner-stats .exam-completed-banner-stat.ko b {
  color: var(--color-ocra);
}
.exam-completed-banner-stats  div.exam-completed-banner-stat-graph {
  min-width: 2rem;
}
.exam-completed-banner-text {
  font-size: var(--font-size-small);
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  padding: 0.3em 1em;
}
.exam-completed-banner .exam-completed-banner-cta {
  display: flex;
  gap: 0.5rem;
}

/* workaround for Safari */
.exam-completed-banner .progress-ring-container svg.circular-meter {
  max-width: 2rem;
  max-height: 1.9rem;
}