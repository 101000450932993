.manage-template-container {
  margin: 0;
  height: 100%;
  display: flex;
  gap: 5rem;
  overflow: hidden;
}
.manage-template-container > .manage-template-create,
.manage-template-container > .manage-template-templates {
  padding: 3rem 3rem 3rem 6rem;
  position: relative;
  overflow: auto;
  height: 100%;
  min-width: 100%;
  transition: margin-left 0.3s ease-in-out;
}
.manage-template-container.show-create > .manage-template-templates {
  margin-left: calc(-100% - 5rem);
}
.manage-template-container > .manage-template-create {
  padding: 0 2rem 0 4rem;
  display: flex;
  flex-direction: column;
}

.manage-template-slide-grid-container {
  overflow: auto;
  flex-grow: 3;
  margin-left: -1.4rem;
}
.manage-template-slide-grid {
  display: grid;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  gap: 1.4rem 0;
  margin: 1rem 0;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  --number-of-columns: 3;
}
.manage-template-slide-grid-cell {
  font-size: var(--font-size-xsmall);
  text-align: center;
  position: relative;
  margin-left: 1.4rem;
}

.manage-template-container .manage-template-slide-grid-cell-add {
  position: absolute;
  right: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.manage-template-container .manage-template-slide-grid-cell:hover .manage-template-slide-grid-cell-add,
.manage-template-container .manage-template-slide-grid-cell:hover + .manage-template-slide-grid-cell .manage-template-slide-grid-cell-add,
.manage-template-container .manage-template-slide-grid-cell.selected .manage-template-slide-grid-cell-add,
.manage-template-container .manage-template-slide-grid-cell.selected + .manage-template-slide-grid-cell .manage-template-slide-grid-cell-add {
  opacity: 0.5;
}
.manage-template-container .manage-template-slide-grid-cell-add::before,
.manage-template-container .manage-template-slide-grid-cell-add::after {
  content: '';
  flex-grow: 2;
  border-left: var(--border-dashed) var(--color-grey);
}
.manage-template-container .manage-template-slide-grid-cell-add:hover {
  opacity: 1;
}

.manage-template-slide-grid-cell.add-new > .manage-template-examination-live-slide {
  background: transparent;
  border: var(--border-dashed-thick) var(--color-xxlight-grey);
  font-size: var(--font-size-large);
  justify-content: center;
}
.manage-template-slide-grid-cell.selected {
  z-index: 10;
}
.manage-template-slide-grid-cell.selected > .manage-template-examination-live-slide {
  background: var(--color-darkcommon-xxlight-grey);
}
.manage-template-create-header {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  border-bottom: var(--border) var(--color-xxlight-grey);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  background: var(--color-white);
  padding: 1rem 0 0.5rem;
}
.manage-template-create-footer {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  border-top: var(--border) var(--color-xxlight-grey);
  position: relative;
  width: 100%;
  z-index: 10;
  background: var(--color-white);
  padding: 1rem 0;
}
.manage-template-create-header h1 {
  margin: 0 0 0.5rem;
  font-size: var(--font-size-large);
}
.manage-template-create-title {
  flex-grow: 2;
}
.manage-template-create-title-import {
  position: absolute;
  top: 1rem;
  right: 0;
  font-size: var(--font-size-small);
}
.manage-template-create-title-input {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.manage-template-create-title-input label {
  white-space: nowrap;
}

.manage-template-examination-live-slide {
  position: relative;
  display: flex;
  aspect-ratio: 16 / 10;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  background: var(--color-darkcommon-xxxlight-grey);
  transition: background-color .3s ease-in-out;
  border: 0.3rem solid transparent;
  cursor: pointer;
  animation: examination-live-slide-fadein 0.6s ease-in-out forwards;
}
.manage-template-examination-live-options {
  position: absolute;
  top: calc(100% - 0.2rem);
  width: 100%;
  background: var(--color-darkcommon-xxlight-grey);
  border-bottom-left-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  padding: 0.5rem 0.2rem;
}
.manage-template-examination-live-delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--font-size-large);
  padding: 0.2rem;
  cursor: pointer;
}
.manage-template-examination-live-delete:hover {
  color: var(--color-black);
}

.manage-template-container ul.manage-template-positions {
  border: var(--border) var(--color-xxlight-grey);
  margin-top: 2rem;
}
.manage-template-container ul.manage-template-positions li {
  display: flex;
  width: 100%;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
}
.manage-template-container ul.manage-template-positions li:hover {
  background: var(--color-xxxlight-grey);
}
.manage-template-container ul.manage-template-positions li:last-child {
  border-bottom: 0;
}
.manage-template-container ul.manage-template-positions li > span {
  flex-basis: 40%;
}
.manage-template-container ul.manage-template-positions li > span:last-child {
  flex-basis: 20%;
  font-size: var(--font-size-small);
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
}
.manage-template-container ul.manage-template-positions li.header {
  border: 0;
  padding: 0 1rem;
}
.manage-template-container ul.manage-template-positions li.header span {
  height: 0;
  margin-top: -1.5rem;
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.manage-template-container .manage-template-add-button {
  font-size: var(--font-size-xxlarge);
  margin: 1rem 0;
  text-align: center;
}

.manage-template-container .manage-template-create-position > h2 {
  margin: 0 0 1rem 0;
}
.manage-template-container .manage-template-create-position > div {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.manage-template-container .manage-template-grid-warning {
  text-align: center;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  color: var(--color-light-grey);
}

.live-checkbox{
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.mode-info-checked {
  color: var(--color-ocra);
}

.mode-info {
  color: var(--color-light-grey);
  font-size: var(--font-size-xsmall);
}