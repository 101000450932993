.examlist-dt-container {
  padding: 2rem;
  padding-left: 5rem;
}

.examlist-dt-container .examlist-dt-exams {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.examlist-dt-container .examlist-dt-load-more {
  padding: 2rem;
  font-size: var(--font-size-small);
  text-align: center;
}