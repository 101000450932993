.progress-ring-container {
  position: relative;
  line-height: 1;
}
.progress-ring-container svg.circular-meter {
  width: 100%;
  height: auto;
  aspect-ratio: 11 / 10;
}

.progress-ring-container svg.circular-meter #bar {
  stroke: var(--color-grey-40);
}
.progress-ring-container svg.circular-meter #progress {
  transition: stroke-dashoffset 0.35s, stroke 0.35s;
  stroke: var(--color-blue);
}
.progress-ring-container.color-ocra svg.circular-meter #progress {
  stroke: var(--color-ocra);
}
.progress-ring-container label {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  font-size: 0.6em;
  text-align: center;
  transition: color 0.35s;
}
.progress-ring-container.color-ocra label {
  color: var(--color-ocra);
}
