.medications-search-bar-container {
  display:block;
  width:100%;
  position:relative;
}
.medications-search-bar-container .medications-search-bar {
  display:block;
  position:relative;
}
.medications-search-bar-container .medications-search-bar input {
  width:100%;
}
.medications-search-bar-container .medications-search-bar small {
  display: block;
  text-align: left;
  margin-left: 2.2rem;
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
}

.medications-search-bar-container .medications-search-bar-autocomplete {
  position: absolute;
  z-index:10;
  top: 100%;
  left: 0;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  width: 100%;
  text-align: left;
  max-height: 70vh;
  overflow: auto;
  box-shadow: var(--common-box-shadow);
}
.medications-search-bar-container .medications-search-bar-autocomplete ul {
  list-style: none;
  padding: .5rem 1rem;
}
.medications-search-bar-container .medications-search-bar-autocomplete > ul > li {
  margin: 0;
  padding: .7em 1rem 1em;
  border-bottom: var(--border) var(--color-xlight-grey);
  cursor:pointer;
}
.medications-search-bar-container .medications-search-bar-autocomplete > ul > li:hover {
  background:var(--color-xlight-grey);
}
.medications-search-bar-container .medications-search-bar-autocomplete > ul > li:last-child {
  border-bottom: 0;
}
.medications-search-bar-container .medications-search-bar-autocomplete .highlight {
  font-weight:bold;
  border-bottom: var(--border-dashed);
}
.medications-search-bar-container .medication-name {
  color:var(--color-black);
}
.medications-search-bar-container .medication-is-teratogenic {
  font-size: var(--font-size-xsmall);
  margin-left: 1rem;
  vertical-align: middle;
  background: var(--color-black);
  border-radius: var(--border-radius-large);
  color: var(--color-xlight-grey);
  padding: .2em .6em;
}
.medications-search-bar-container .medication-description {
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
  margin: 1em 0 0;
  border-left: var(--border-thick) var(--color-xxlight-grey);
  padding-left: 0.5em;
}
.medications-search-bar-container .medication-description.long-text {
  columns: 3;
  column-rule: var(--border-thick) var(--color-xxlight-grey);
}
.medications-search-bar-container .medication-malformations li {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.5em;
  display: inline-block;
  white-space: nowrap;
  margin: 0.5em 0.5em 0.5em 0;
}
.medications-search-bar-container .medication-malformations {
  margin-top: 1rem;
  font-size: var(--font-size-small);
}
.medications-search-bar-container .medication-medications ul {
  padding: 0 !important;
  font-size:var(--font-size-small);
  line-height:var(--font-size-small);
}
.medications-search-bar-container .medication-medications li {
  display:inline-block;
  white-space: nowrap;
  margin: 0 .2em 0 0;
}
.medications-search-bar-container .medication-medications li:after {
  content:',';
}
.medications-search-bar-container .medication-medications li:last-child:after {
  content:'';
}

.medications-search-bar-list {
  margin:2rem 0;
}
.medications-search-bar-list ul {
  list-style:none;
  padding:0;
}
.medications-search-bar-list > ul > li {
  position:relative;
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius-small);
  padding: 1em 2rem;
  text-align: left;
  margin: 0 0 .5rem;
}
.medications-search-bar-list .medication-remove {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: var(--border);
  border-radius: var(--border-radius-large);
  width: 1.5em;
  display: block;
  text-align: center;
  cursor:pointer;
}
.medications-search-bar-list .medication-remove:hover {
  color:var(--color-black);
  background:var(--color-red);
  border-color:var(--color-red);
}