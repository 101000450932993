.inline-editing {
  display: inline-block;
  position: relative;
  --hover-padding-horizontal: 0.5rem;
  --hover-padding-vertical: 0.3rem;
}
.inline-editing.inline-textarea {
  display: block;
  word-break: break-word;
}
.inline-editing :is([contenteditable=true], .contenteditable) {
  cursor: pointer;
  border-bottom: var(--border-thick) var(--color-light-grey-80);
  min-height: 1em;
  min-width: 0;
}
.inline-editing :is([contenteditable="true"], .contenteditable):empty {
  min-width: 3rem;
}
.inline-editing :is([contenteditable=true], .contenteditable):hover {
  border-bottom-color: transparent;
}
.inline-editing.full-width,
.inline-editing.full-width :is([contenteditable=true], .contenteditable) {
  min-width: 100%;
}
.inline-editing .inline-editing-value {
  position: relative;
}
.inline-editing.is-editing {
  z-index: 500;
}
.inline-editing.is-active:hover::after,
.inline-editing.is-active.is-editing::after {
  content: '';
  position: absolute;
  left: calc(var(--hover-padding-horizontal) * -1);
  top: calc(var(--hover-padding-vertical) * -1);
  width: calc(100% + calc(var(--hover-padding-horizontal) * 2));
  height: calc(100% +  calc(var(--hover-padding-vertical) * 2));
  border: var(--border) var(--color-grey);
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  z-index: 0;
}
.inline-editing.is-active:hover::after {
  opacity: 1;
}
.inline-editing.is-active.is-editing {
  z-index: 20;
}
.inline-editing.is-active > div {
  position: relative;
  z-index: 10;
}

.inline-editing-not-visible-mask {
  cursor: pointer !important;
  position: absolute !important;
  top: -0.25rem;
  left: -0.25rem;
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
  background: repeating-linear-gradient( -45deg, var(--color-grey-40), var(--color-grey-40) 0.05rem, transparent 0.05rem, transparent 0.3rem);
  z-index: 100;
  opacity: 0.6;
  color: var(--color-black);
  font-size: var(--font-size-large);
  transition: opacity 0.3s ease-in-out;
}
.inline-editing-not-visible-mask[data-allow-updates=true] {
  pointer-events: none;
}
.inline-editing-not-visible-mask:hover {
  opacity: 1;
}
.inline-editing-not-visible-mask > span {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.inline-editing-not-visible-mask:hover > span {
  opacity: 1;
}
