.fetus-controller {
  width: 100%;
}
.fetus-controller > div {
  position: relative;
}
.fetus-controller > div > .go-to-next {
  position: absolute;
  right: 0.3em;
  top: 0.2rem;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  font-size: 3.2rem;
  text-align: center;
  color: var(--color-white);
  background: var(--color-blue);
  cursor: pointer;
  line-height: 5rem;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}
.fetus-controller > div > .go-to-next:hover {
  background: var(--color-light-blue);
}
.fetus-controller > div > .go-to-next > span {
  width: 5rem;
}
.fetus-controller > div > .go-to-next.active > span:first-child {
  color: var(--color-common-blue);
}
.fetus-controller > div > .go-to-next.play > span:first-child {
  animation: go-to-next-play 1s;
}

@keyframes go-to-next-play {
  40% {
    margin-left: 0;
  }
  60% {
    margin-left: -100%;
  }
  80% {
    margin-left: -100%;
  }
  100% {
    margin-left: -200%;
  }
}

.fetus-controller > div > svg {
  width: 100%;
  height: auto;
}
.fetus-controller > div > svg .shape {
  fill: var(--color-white);
  stroke: var(--color-white);
  stroke-width: 6;
}
.fetus-controller > div > svg circle.shape {
  fill: var(--color-xxlight-grey);
}
.fetus-controller > div > svg .zone {
  fill: var(--color-white);
}
.fetus-controller > div > svg .zone.hover {
  fill: var(--color-light-grey);
}
.fetus-controller > div > svg .zone.current {
  fill: var(--color-black) !important;
}
.App.dark .fetus-controller > div > svg .zone.current {
  fill: var(--color-black);
}
.fetus-controller > div > svg .zone.current.hover {
  fill: var(--color-dark-grey);
}
.fetus-controller > div > svg .zone.completed,
.fetus-controller > div > svg .zone.appendages.completed {
  fill: var(--color-common-blue);
}
.fetus-controller > div > svg .zone.completed.hover {
  fill: var(--color-xlight-blue);
}
.fetus-controller > div > svg .zone.appendages {
  opacity: 1;
  fill: var(--color-xxlight-grey);
}
.fetus-controller > div > svg .click {
  fill: transparent;
  cursor: pointer;
}

.fetus-controller > div > svg .marker {
  fill: var(--color-common-white);
  stroke-width: 10;
  stroke: var(--color-common-light-grey);
  stroke-opacity: 0.5;
  animation: marker-pulse 2s ease-in-out infinite;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.App.dark .fetus-controller > div > svg .marker {
  fill: var(--color-black);
  stroke: var(--color-grey);
}
.fetus-controller > div > svg .marker.visible {
  opacity: 1;
}

.fetus-controller > div > svg .unusual {
  fill: var(--color-ocra);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.fetus-controller > div > svg .unusual.visible {
  opacity: 1;
}

@keyframes marker-pulse {
  from {
    stroke: var(--color-light-grey);
    stroke-width: 0;
    stroke-opacity: 1;
  }
  to {
    stroke: var(--color-grey);
    stroke-width: 0.5em;
    stroke-opacity: 0;
  }
}
