input[type="submit"],
input[type="button"],
.button,
button {
  font-family: "Metropolis", sans-serif;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  background: var(--color-blue);
  color: var(--color-white);
  font-size: 1em;
  line-height: 1em;
  padding: 0.8em 1.2em;
  min-width: 3em;
  text-align:center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
}

.button.small {
  padding: 0.3em 0.6em;
  min-width: 0;
}
.button.input {
  padding: 0.5em 0.8em;
  min-width: 0;
}
.button.full-width {
  width: 100%;
}
.button.full-width > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button.small .icon {
  font-size: 0.7em;
  margin: 0.20em;
}
.button.fontsize-12 span.label{
  font-size: 1rem;
}

input[type="submit"].gap-right .icon:first-child,
input[type="button"].gap-right .icon:first-child,
.button.gap-right .icon:first-child,
button.gap-right .icon:first-child {
  margin-right: .6em;
  margin-left: -.3em !important;
}

input[type="submit"].gap-left .icon:last-child,
input[type="button"].gap-left .icon:last-child,
.button.gap-left .icon:last-child,
button.gap-left .icon:last-child {
  margin-left: .6em;
  margin-right: -.3em !important;
}

input[type="submit"]:hover,
input[type="button"]:hover,
.button:hover,
button:hover {
  background: var(--color-light-blue);
}
input[type="submit"]:active,
input[type="button"]:active,
.button:active,
button:active {
  background: var(--color-ocra);
}
input[type="submit"]:disabled,
input[type="button"]:disabled,
.button[data-disabled="true"],
button:disabled {
  background: var(--color-xlight-grey);
  border-color: var(--color-grey) !important;
  color: var(--color-grey) !important;
  cursor: default;
}

input[type="submit"].dim,
input[type="button"].dim,
.button.dim,
button.dim {
  background: var(--color-xlight-grey);
  color: var(--color-blue);
}

input[type="submit"].outline,
input[type="button"].outline,
.button.outline,
button.outline {
  background: transparent;
  color: var(--color-blue);
}
input[type="submit"].outline::after,
input[type="button"].outline::after,
.button.outline::after,
button.outline::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--border-thick);
  border-radius: var(--border-radius-small);
  pointer-events: none;
}
input[type="submit"].outline:hover,
input[type="button"].outline:hover,
.button.outline:hover,
button.outline:hover {
  color: var(--color-light-blue);
}

.button.contained-shadow {
  border-radius: 0.3rem;
  background: var(--color-blue);
  box-shadow: 0 0 15px 0 var(--color-xxlight-grey-80) inset;
}

.button > .hint {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  bottom: 100%;
  left: 50%;
  background-color: var(--color-xlight-grey);
  color: var(--color-black);
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
  max-width: 8rem;
  min-width: 8rem;
  translate: -50% -0em;
  padding: 0.5em;
  box-shadow: var(--common-box-shadow);
  z-index: 10;
  transition: translate 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.button > .hint::after {
  content: '';
  position: absolute;
  top: 90%;
  left: calc(50% - 0.5em);
  border: 1em solid transparent;
  border-top-color: var(--color-xlight-grey);
  border-bottom: 0;
}
.button:hover > .hint {
  opacity: 1;
  translate: -50% -0.3em;
}

input[type="submit"].color-common,
input[type="button"].color-common,
.button.color-common,
button.color-common {
  background: var(--color-common-blue);
  color: var(--color-common-white);
}
input[type="submit"].color-common:hover,
input[type="button"].color-common:hover,
.button.color-common:hover,
button.color-common:hover {
  background: var(--color-common-light-blue);
}
input[type="submit"].color-common:active,
input[type="button"].color-common:active,
.button.color-common:active,
button.color-common:active {
  background: var(--color-common-ocra);
}
input[type="submit"].color-common:disabled,
input[type="button"].color-common:disabled,
.button.color-common[data-disabled="true"],
button.color-common:disabled {
  background: var(--color-common-grey);
}

input[type="submit"].color-common.outline,
input[type="button"].color-common.outline,
.button.color-common.outline,
button.color-common.outline {
  color: var(--color-common-blue);
  background-color: transparent;
}
input[type="submit"].color-common.outline:hover,
input[type="button"].color-common.outline:hover,
.button.color-common.outline:hover,
button.color-common.outline:hover {
  color: var(--color-common-light-blue);
}


input[type="submit"].color-ocra,
input[type="button"].color-ocra,
.button.color-ocra,
button.color-ocra {
  background: var(--color-ocra);
  color: var(--color-white);
}
input[type="submit"].color-ocra:hover,
input[type="button"].color-ocra:hover,
.button.color-ocra:hover,
button.color-ocra:hover {
  background: var(--color-light-ocra);
}
input[type="submit"].color-ocra:active,
input[type="button"].color-ocra:active,
.button.color-ocra:active,
button.color-ocra:active {
  background: var(--color-ocra);
}
input[type="submit"].color-ocra:disabled,
input[type="button"].color-ocra:disabled,
.button.color-ocra[data-disabled="true"],
button.color-ocra:disabled {
  background: var(--color-common-grey);
}

input[type="submit"].color-ocra.outline,
input[type="button"].color-ocra.outline,
.button.color-ocra.outline,
button.color-ocra.outline {
  color: var(--color-ocra);
  background-color: transparent;
}
input[type="submit"].color-ocra.outline:hover,
input[type="button"].color-ocra.outline:hover,
.button.color-ocra.outline:hover,
button.color-ocra.outline:hover {
  color: var(--color-light-ocra);
}

input[type="submit"].color-red,
input[type="button"].color-red,
.button.color-red,
button.color-red {
  background: var(--color-red);
  color: var(--color-white);
}
input[type="submit"].color-red:hover,
input[type="button"].color-red:hover,
.button.color-red:hover,
button.color-red:hover {
  background: var(--color-light-red);
}
input[type="submit"].color-red:active,
input[type="button"].color-red:active,
.button.color-red:active,
button.color-red:active {
  background: var(--color-red);
}
input[type="submit"].color-red:disabled,
input[type="button"].color-red:disabled,
.button.color-red[data-disabled="true"],
button.color-red:disabled {
  background: var(--color-common-grey);
}

input[type="submit"].color-red.outline,
input[type="button"].color-red.outline,
.button.color-red.outline,
button.color-red.outline {
  color: var(--color-red);
  background-color: transparent;
}
input[type="submit"].color-red.outline:hover,
input[type="button"].color-red.outline:hover,
.button.color-ocra.red:hover,
button.color-ocra.red:hover {
  color: var(--color-red) !important;
}

input[type="submit"].color-grey,
input[type="button"].color-grey,
.button.color-grey,
button.color-grey {
  background: var(--color-xlight-grey);
  color: var(--color-blue);
}
input[type="submit"].color-grey:hover,
input[type="button"].color-grey:hover,
.button.color-grey:hover,
button.color-grey:hover {
  background: var(--color-grey);
}
input[type="submit"].color-grey:active,
input[type="button"].color-grey:active,
.button.color-grey:active,
button.color-grey:active {
  background: var(--color-xlight-grey);
}
input[type="submit"].color-grey:disabled,
input[type="button"].color-grey:disabled,
.button.color-red[data-disabled="true"],
button.color-red:disabled {
  background: var(--color-common-xlight-grey);
}

input[type="submit"].color-grey.outline,
input[type="button"].color-grey.outline,
.button.color-grey.outline,
button.color-grey.outline {
  color: var(--color-xlight-grey);
  background-color: transparent;
}
input[type="submit"].color-grey.outline:hover,
input[type="button"].color-grey.outline:hover,
.button.color-ocra.grey:hover,
button.color-ocra.grey:hover {
  color: var(--color-xlight-grey) !important;
}


input[type="submit"].link,
input[type="button"].link,
.button.link,
button.link {
  background: transparent;
  color: var(--color-blue);
  border: 0;
}

input[type="submit"].link span:not(.icon),
input[type="button"].link span:not(.icon),
.button.link span:not(.icon),
button.link span:not(.icon) {
  text-decoration: underline;
}

input[type="submit"].link:disabled,
input[type="button"].link:disabled,
.button.link[data-disabled="true"],
button.link:disabled {
  opacity: 0.3;
}

input[type="submit"].link:disabled span:not(.icon),
input[type="button"].link:disabled span:not(.icon),
.button.link[data-disabled="true"] span:not(.icon),
button.link:disabled span:not(.icon) {
  text-decoration: none;
}

.button.text {
  background: transparent;
  color: var(--color-blue);
  padding: 0;
  line-height: 1;
  font-size: 1em;
}