.reset-password-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding-top: 4rem;
}

.reset-password-logo {
    position: fixed;
    top: 0;
    left: 0;
    padding: var(--font-size-large);
}

.reset-password-logo > img {
    width: 7.5rem;
}

.reset-password-form {
    width: 45rem;
    padding-bottom: var(--font-size-xxlarge);
}

.reset-password-form-title {
    width: 100%;

    font-style: normal;
    font-weight: 800;
    font-size: var(--font-size-xxlarge);
    line-height: var(--font-size-xxlarge);

    text-align: center;

    margin-bottom: var(--font-size-xxlarge);
}

.reset-password-form-section-title {
    text-transform: uppercase;

    font-style: normal;
    font-weight: 400;

    letter-spacing: 0.1em;

    color: var(--color-black);
    margin-bottom: var(--font-size-xsmall);
    margin-top: var(--font-size-xxlarge);
}

.reset-password-tooltip-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: var(--font-size-large);
}

.reset-password-tooltip-icon {
    width: var(--font-size-large);
    height: var(--font-size-large);
    margin-left: var(--font-size-xsmall);
    cursor: pointer;
    position: relative;
}

.reset-password-tooltip-message {
  position: absolute;
  top: 0;
  left: calc(var(--font-size-large) + var(--font-size-xsmall));
  background-color: var(--color-dark-blue);
  border-radius: var(--border-radius-small);
  padding: var(--font-size-xsmall);
  color: var(--color-white);
  font-size: var(--font-size-small);
  text-transform: none;
  letter-spacing: initial;
  z-index: 10;
  width: 25rem;
}

.reset-password-tooltip-list {
  list-style-type: '-';
  padding-inline-start: var(--font-size-xsmall);
}

.reset-password-field-splitter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.reset-password-field-wrapper {
    margin-bottom: var(--font-size-medium);
    margin-top: var(--font-size-medium);
}

.reset-password-field-wrapper.half {
    width: 50%;
}

.reset-password-field-wrapper > div > label {
    font-style: normal;
    font-weight: 400;
}

.reset-password-field-wrapper > input {
    width: 100%;
    height: var(--font-size-xxxlarge);
}

.reset-password-input-error {
  border-color: var(--color-xlight-red) !important;
  border-width: var(--border-thick-size) !important;
}

.reset-password-button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: var(--font-size-xsmall);
}

.reset-password-contact {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-size-large);

    text-align: center;

    color: var(--color-black);
}

.reset-password-contact {
    margin-top: var(--font-size-xxxlarge);
}

.register-mail-link {
    font-style: normal;
    font-weight: 800;
}

.reset-password-field-error {
    margin-top: var(--font-size-xsmall);

    font-style: normal;
    font-weight: 700;


    color: var(--color-xlight-red);
}

.reset-password-country-number-wrapper {
    display: flex;
    gap: var(--font-size-xsmall);
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

.reset-password-id-number-type-wrapper {
    height: var(--font-size-xxxlarge);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: hidden;
    border-radius: var(--border-radius-small);
    background-color: var(--color-xlight-grey);
}

.reset-password-id-number-type {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.15s;
    justify-content: center;
    width: 5rem;
}

.reset-password-id-number-type.selected {
    background-color: var(--color-blue);
    color: var(--color-xxxlight-grey);
}

.reset-password-red-message {
  color: var(--color-xlight-red);
}
