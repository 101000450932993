/* drag and drop */
.is-dragging {
  overflow: hidden !important;
  touch-action: none;
}
.drag-hidden {
  display: none !important;
}
.drag-scroll-up,
.drag-scroll-down {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  z-index: 1000;
  pointer-events: none;
}
.drag-scroll-down {
  top: auto;
  bottom: 0;
}
.is-dragging > .drag-scroll-up,
.is-dragging > .drag-scroll-down {
  pointer-events: all;
}
.drag-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background: var(--color-common-black);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  opacity: 0.5;
  z-index: 200;
  touch-action: none;
}
