.risk-factors-sidebar-container {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
}
.risk-factors-sidebar-container ul {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.risk-factors-sidebar-container ul li {
  position: relative;
  padding: .5rem 0;
  border-top: var(--border) var(--color-xxlight-grey);
  margin: 0;
}
.risk-factors-sidebar-container ul:first-of-type li:first-of-type {
  border-top: 0;
}
.risk-factors-sidebar-container .medication li .dismiss {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 10;
}
.risk-factors-sidebar-container .medication li .dismiss:hover {
  color: var(--color-black);
}
.risk-factors-sidebar-container .medication .medication-label {
  display: block;
  margin-bottom: 0.4em;
}

.risk-factors-sidebar-container ul li.recommended {
  background: var(--color-xxlight-grey-40);
  margin: 0.2rem 0;
  padding: 0.5rem;
  border: 0;
  border-radius: var(--border-radius);
}

.risk-factors-sidebar-container .fetus-sex-popup {
  font-size: var(--font-size-medium);
}
