.instant-qr-code {
  padding: 0.315rem;
  display: flex;
  align-items: center;
  font-size: var(--font-size-large);
  color: var(--color-white);
  background: var(--color-blue);
  border-radius: var(--border-radius-small);
  border: var(--border);

  cursor: pointer;
}

.instant-qr-code.dim {
  background: var(--color-xlight-grey);
  color: var(--color-blue);
  border: 0;
}
