.fetus-container .fetus {
  width: 100%;
}
.fetus-container .fetus svg {
  width: 100%;
  height: auto;
}
.fetus-container svg .shape {
  stroke: var(--color-white);
  stroke-width: 14;
  stroke-linejoin: "round";
}
.fetus-container svg .zone.structures {
  fill: var(--color-xxxlight-grey);
}
.fetus-container svg .zone.validated {
  fill: var(--color-light-grey);
}
.fetus-container svg .unusual {
  fill: var(--color-ocra);
  stroke: var(--color-white);
  stroke-width: 6;
}
.fetus-container svg .hidden {
  display: none;
}

.fetus-container svg {
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
}
.fetus-container .flip {
  transform: scaleX(-1);
}
.fetus-container .rotate-0 svg {
  transform: rotate(0deg);
}
.fetus-container .rotate-90 svg {
  transform: rotate(90deg);
}
.fetus-container .rotate-180 svg {
  transform: rotate(180deg);
}
.fetus-container .rotate-270 svg {
  transform: rotate(270deg);
}
