.appointments-container {
  padding: 2rem 2rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
  color: var(--color-black);
}

.appointments-container > .appointments-container-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.appointments-container > .appointments-container-header > .sync-btn {
  padding-right: 0.5rem;
  width: 9rem;
  text-align: center;
}
