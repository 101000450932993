.checkbox {
  display: inline-flex;
  vertical-align: middle;
  margin: .2rem 0;
  cursor: pointer;
}

.checkbox:hover {
  color: var(--color-blue);
}

.checkbox .checkbox-field {
  display: inline-flex;
  position: relative;
  width: 2.5em;
  min-width: 2.5em;
  height: 1.5em;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  vertical-align: middle;
  box-shadow: var(--box-shadow-thin);
}

.checkbox:hover .checkbox-field {
  background: var(--color-xlight-grey);
}

.checkbox .checkbox-field .checkbox-toggle {
  display: flex;
  position: absolute;
  font-size: 1em;
  top: 0.1rem;
  left: 0.1rem;
  width: calc(1.5em - 0.2rem);
  height: calc(1.5em - 0.2rem);
  background: var(--color-white);
  border-radius: var(--border-radius);
  color: var(--color-white);
  transition: left .3s ease-in-out, background-color .3s ease-in-out;
  align-items: center;
  justify-content: center;
}

.checkbox.checked .checkbox-field .checkbox-toggle {
  left: calc(100% - 1.4em);
  background-color: var(--color-ocra);
}

.checkbox:hover .checkbox-field .checkbox-toggle {
  border-color: var(--color-blue);
}

.checkbox .checkbox-label {
  display: inline-block;
  margin-left: .2em;
  vertical-align: middle;
  color: var(--color-light-blue);
}

.checkbox:hover .checkbox-label,
.checkbox.checked .checkbox-label {
  color: var(--color-blue);
}

.checkbox.disabled,
.checkbox.disabled:hover,
.checkbox.disabled *,
.checkbox.disabled:hover * {
  cursor: default;
  background-color: unset;
  border-color: initial;
  opacity: .8;
}

.checkbox .before-input {
  margin-right: 1rem;
}
