.notifications-panel {
  --duration: 3000ms;
  display: flex;
  gap: 0.5rem;
  z-index: 999;
  background: var(--color-common-grey);
  font-size: var(--font-size-medium);
  min-height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  max-height: 3rem;
  transition: min-height 0.3s ease-in-out;
  align-items: center;
  overflow: hidden;
}
.notifications-panel.open {
  min-height: 3rem;
  font-size: medium;
}

.notifications-panel .notifications-nav-arrows {
  min-width: 4rem;
  text-align: center;
  font-size: var(--font-size-medium);
}
.notifications-panel .notifications-nav-arrows > span {
  margin: 0 0.3rem;
  opacity: 0.2;
}
.notifications-panel .notifications-nav-arrows > span.active {
  opacity: 1;
  cursor: pointer;
}
.notifications-panel .notifications-nav-arrows > span.active:hover {
  opacity: 0.5;
}

.notifications-panel .notifications-notifications {
  flex-grow: 2;
  padding: 0 1em;
  overflow: auto;
  height: 100%;
  scroll-snap-type: y mandatory;
}
.notifications-panel .notifications-notifications > div {
  scroll-snap-align: center;
}

