.button-back_wrapper {
    color: var(--color-black);
    border: var(--border) var(--color-grey-40);
    border-radius: 5em;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.button-back_wrapper:hover {
    border-color: var(--color-black);
}
.button-back_wrapper.type-close {
    border-color: transparent;
}