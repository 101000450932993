#debug-panel {
  --panel-width: 27rem;
  position: fixed;
  top: 0;
  right: calc(var(--panel-width) * -1);
  height: 100vh;
  width: var(--panel-width);
  background: var(--color-common-white);
  z-index: 10000;
  transition: right .3s ease-in-out;
}
#debug-panel.open {
  right: 0;
  box-shadow: var(--common-box-shadow);
}

#debug-panel .debug-panel-template-editor-toggle {
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
#debug-panel .debug-panel-template-editor-toggle > button {
  width: 100%;
}

#debug-panel .debug-panel-toggle {
  position: absolute;
  bottom: 0;
  right: 100%;
  cursor: pointer;
  color: var(--color-black);
  background: var(--color-ocra);
  padding: 0.2em;
  line-height: 1;
  font-size: var(--font-size-small);
  border-top-left-radius: 2rem;
  cursor: pointer;
}
#debug-panel .debug-panel-toggle:hover {
  background: var(--color-light-ocra);
}

#debug-panel .debug-panel-content {
  padding: 1em;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
  color: var(--color-common-black);
  width: 100%;
  height: 100%;
  background: var(--color-common-xxxlight-grey);
  scrollbar-width: auto;
  overflow: auto;
}

#debug-panel .debug-section {
  display: flex;
  flex-direction: column;
  border-bottom: var(--border) var(--color-common-xlight-grey);
  padding: 0.5rem 0 0.5rem 1rem;
}
#debug-panel .debug-section h2 {
  font-size: var(--font-size-xsmall);
  color: var(--color-white);
  margin: -0.5rem 0 0.5rem -1rem;
  background-color: var(--color-ocra);
  text-align: center;
  padding: 0.2rem;
  cursor: pointer;
}
#debug-panel .debug-section h2 .icon {
  transition: rotate 0.3s linear;
}
#debug-panel .debug-section.open h2 .icon {
  rotate: 180deg;
}
#debug-panel .debug-subsection_title button {
  display: none;
}
#debug-panel .debug-subsection_title:hover button {
  display: inherit;
}
#debug-panel .debug-section h3 {
  font-size: var(--font-size-xsmall);
  margin: 0em 0 0.2em -1rem;
}
#debug-panel .debug-section h3 span {
  font-weight: normal;
  background: var(--color-common-xlight-grey);
  font-size: var(--font-size-xxsmall);
  display: inline-block;
  white-space: nowrap;
  margin-left: 0.5em;
}

#debug-panel .debug-subsection:not(:first-of-type) {
  margin-top: 0.6rem;
}

#debug-panel .debug-section .printing-config-plane,
#debug-panel .debug-section .sharing-config-plane,
#debug-panel .debug-section .ff-item {
  display: inline-block;
  margin: 0 0.2em 0.2em 0;
  white-space: nowrap;
  background: var(--color-common-xlight-grey);
  padding: 0 0.3em;
  border-radius: var(--border-radius-small);
}
#debug-panel .debug-section .printing-config-plane:hover,
#debug-panel .debug-section .sharing-config-plane:hover,
#debug-panel .debug-section .ff-item:hover {
  background: var(--color-common-xlight-blue);
}

#debug-panel .debug-subsection .planes-list {
  display: flex;
  flex-wrap: wrap;
}

#debug-panel .debug-subsection .measurements :is(th, td) {
  border-top: var(--border) var(--color-common-light-grey);
}

#debug-panel .debug-subsection .measurements td {
  text-align: center;
}

#debug-panel .debug-subsection .measurements .unused {
  background-color: red;
  color: white;
  padding: 0 0.2rem;
  border-radius: 1rem;
}

#debug-panel .debug-subsection .measurements  th:first-child {
  width: 12rem;
}

#debug-panel .debug-subsection .measurements .unextracted-table th:not(:first-child) {
  text-align: center;
}

#debug-panel .debug-subsection > div > div {
  display: flex;
  gap: 0.3em;
  flex-wrap: wrap;
  border-top: 1px solid var(--color-common-xlight-grey);
}
#debug-panel .debug-subsection > div > div b {
  white-space: nowrap;
  cursor: pointer;
}
#debug-panel .debug-subsection > div > div .value {
  display: none;
  overflow-wrap: break-word;
  padding: 0.2rem 0 0.6rem;
  width: 100%;
}
#debug-panel .debug-subsection > div > div.open .value {
  display: block;
}
#debug-panel .debug-subsection > div > div.open .icon {
  rotate: 180deg;
}
#debug-panel .debug-subsection > div > div .value table {
  margin-left: 2em;
}
#debug-panel .debug-subsection > div > div .value :is(th, td) {
  font-weight: normal;
  border-bottom: var(--border) var(--color-common-light-grey);
  padding-right: 1em;
}

#debug-panel label {
  text-transform: uppercase;
  font-size: var(--font-size-xxsmall);
  min-width: 3.5rem;
  display: inline-flex;
}
#debug-panel label:after {
  content: '';
  flex-grow: 2;
  border-bottom: var(--border-thick-size) dotted var(--color-common-light-grey);
  align-self: flex-start;
  height: var(--font-size-xsmall);
}
#debug-panel table td {
  padding: 0 0.5em;
}
#debug-panel table tr.spacer td {
  height: 1em;
  border-bottom: var(--border) var(--color-common-grey);
}
#debug-panel table th {
  text-align: left;
  margin-top: 0.5em;
}
#debug-panel textarea {
  color: var(--color-common-black) !important;
}


#debug-panel .debug-panel-template-editor {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100svh;
  z-index: 1000;
  background: var(--color-common-white);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}
#debug-panel .debug-panel-template-editor .debug-panel-textarea {
  width: 100%;
  flex-grow: 2;
  resize: none;
  color: var(--color-common-white) !important;
  background: var(--color-common-black);
  font-family: monospace;
  padding: 1rem;
  font-size: var(--font-size-small);
  scrollbar-width: auto;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}
#debug-panel .debug-panel-template-editor > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-common-black);
  text-align: center;
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  gap: 1rem;
}
#debug-panel .debug-panel-template-editor > div > :nth-child(2) {
  flex-basis: 50%;
}

#debug-panel .debug_audioplayer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
#debug-panel a {
  color: var(--color-common-blue);
}