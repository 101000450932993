.search-bar {
  position: relative;
  font-size: 1rem;
}

.search-bar input {
  margin: 0;
  width: 100%;
}

.search-bar .hint {
  font-size: var(--font-size-small);
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--color-xxlight-grey);
  background: var(--color-white);
  line-height: var(--font-size-small);
  padding: 0.5em 0.5em 0.5em 2rem;
  z-index: 10;
  width: 100%;
  border-radius: var(--border-radius-small);
}

