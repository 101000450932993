.popup-container {
    display:flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
}
.popup-container .popup {
    position:relative;
    z-index: 100000;
    min-width: 60%;
    max-width:90%;
    background:var(--color-white);
    box-shadow:var(--box-shadow);
    border-radius:var(--border-radius);
    padding:var(--font-size-large);
    text-align:center;
}
.popup-container .popup > .cta:not(:first-child) {
    margin-top:var(--font-size-large);
}
