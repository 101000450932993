/* typography */

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/metropolis-bold-wf.woff2") format("woff2"),
    url("./fonts/metropolis-bold-wf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/metropolis-regular-wf.woff2") format("woff2"),
    url("./fonts/metropolis-regular-wf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}