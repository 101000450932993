.examination-review-tabs {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0 1rem;
  background: var(--color-white);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border: var(--border) var(--color-xxlight-grey);
  border-top: 0;
  border-right: 0;
  border-bottom-left-radius: var(--border-radius-small);
}

.examination-review-tabs > div {
  padding: 0.5rem;
  cursor: pointer;
}
.examination-review-tabs > div:hover {
  color: var(--color-light-blue);
}
.examination-review-tabs > div.selected {
  border-bottom: var(--border-thick) var(--color-blue);
}
