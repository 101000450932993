.new-exam-buttons-container {
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  display: inline-flex;
  position: relative;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  align-items: stretch;
}
.new-exam-buttons-container .button-group-options {
  line-height: 1.3;
}
.new-exam-buttons-container .button-group .option {
  white-space: normal;
}

.new-exam-buttons-container.theme-grey {
  background: var(--color-xlight-grey);
}
.new-exam-buttons-container.theme-light {
  background: var(--color-common-white);
}
.new-exam-buttons-container.variant-outline {
  background: transparent;
}

.new-exam-buttons-expand {
  min-width: 1.5em;
  font-size: var(--font-size-medium);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
