.patientname-container {
  color: var(--color-black);
  background: var(--color-white);
  padding: 0.2rem;
  border-radius: var(--border-radius-small);
  border: var(--border) var(--color-white);
  display: flex;
  gap: 0.3rem;
}
.patientname-container.transparent {
  background: none;
  border: none;
  color: inherit;
}

.patientname-container .patientname {
  cursor: pointer;
  white-space: nowrap;
}
.patientname-container .patientname_warning {
  color: var(--color-ocra);
  cursor: pointer;
  position: relative;
}
.patientname-container .patientname_warning_notice {
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50% 0;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  padding: 1rem;
  width: 15rem;
  font-size: var(--font-size-small);
  color: var(--color-black);
}
.patientname-container .patientname_warning_notice::after {
  content: '';
  position: absolute;
  top: -0.4rem;
  left: calc(50% - 0.25rem);
  border: 0.5rem solid transparent;
  border-top: 0;
  border-bottom-color: var(--color-xxlight-grey);
}
.patientname-container .patientname:hover {
  color: var(--color-light-blue);
}
.patientname-container .patientname .fullname {
  overflow: hidden;
  white-space: nowrap;
  max-width: 15rem;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.patientname-container .patientname > .icon {
  margin-right: 0.5em;
}
.patientname-container .patientname .gestational-age {
  margin-left: 0.5em;
  color: var(--color-light-grey);
}

.patientname-popup.open {
  position: fixed;
  z-index: 1100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.patientname-popup-inner {
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius);
  box-shadow: var(--common-box-shadow);
  z-index: 1020;
  display: flex;
  width: 50vw;
  transition: width .3s ease-in-out;
}
.patientname-popup-inner.with-search-results {
  width: 90vw;
}
.patientname-popup-inner .more-options-link {
  text-align: right;
  font-size: var(--font-size-small);
}
.patientname-popup-inner .more-options {
  height: 0;
  overflow: hidden;
  transition: height .3s ease-in-out;
}
.patientname-popup-inner .patientname-popup-patient {
  width: 100%;
  position: relative;
  padding: 3rem;
}
.patientname-popup-inner .patientname-popup-search {
  overflow: hidden;
  width: 0;
  white-space: nowrap;
}
.patientname-popup-inner.with-search-results .patientname-popup-search {
  width: 80%;
  max-height: 80vh;
  border-left: var(--border) var(--color-xxlight-grey);
}
.patientname-popup-inner.with-search-results .patientname-popup-search > div {
  padding: 0.7rem 2rem;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  transition: transform 0.3s ease-in-out;
  height: 100%;
  overflow: auto;
}
.patientname-popup-inner.with-search-results .patientname-popup-search.show-details > div {
  transform: translateX(-100%);
}
.patientname-popup-inner.with-search-results .patientname-popup-search label {
  display: block;
  margin: 1rem 0 0.3rem 0;
}
.patientname-popup-inner.with-search-results .patientname-popup-search label:first-child {
  margin: 2.7rem 0 0.3rem 0;
}
.patientname-popup-inner.with-search-results .patientname-popup-search ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
}
.patientname-popup-inner.with-search-results .patientname-popup-search ul li {
  padding: 0.8rem;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  background: var(--color-xxlight-grey-40);
  margin-bottom: 0.5rem;
}
.patientname-popup-inner.with-search-results .patientname-popup-search ul li:hover {
  color: var(--color-light-blue);
}
.patientname-popup-inner.with-search-results .patientname-popup-search ul li .icon {
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius);
  margin-right: 0.5em;
  font-size: var(--font-size-large);
}
.patientname-popup-inner.with-search-results .patientname-popup-search ul li .patient-patient-details,
.patientname-popup-inner.with-search-results .patientname-popup-search ul li .episode-details {
  flex-grow: 2;
}
.patientname-popup-inner.with-search-results .patient-dob,
.patientname-popup-inner.with-search-results .episode-conceptiondate {
  margin: 0 1em;
  color: var(--color-grey);
}

.patientname-popup-search-details .patientname-popup-search-details-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.3rem 0 1rem;
  font-size: var(--font-size-small);
  position: sticky;
  background: var(--color-xxxlight-grey);
  top: 0;
}
.patientname-popup-search-details .patientname-popup-search-details-header div {
  text-align: center;
  flex-grow: 2;
}
.patientname-popup-search-details .patientname-popup-search-details-header h3 {
  flex-grow: 2;
  margin: 0;
}
.patientname-popup-search-details .patientname-popup-search-details-header .icon {
  font-size: var(--font-size-xxlarge);
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius-large);
  cursor: pointer;
}

.patientname-popup-inner .icon-close {
  font-size: 2em;
  position: absolute;
  top: .5em;
  right: .5em;
  cursor: pointer;
  margin: 0 !important;
}
.patientname-popup-inner .icon-close:hover {
  color: var(--color-light-blue);
}
.patientname-popup-inner .warning {
  background: var(--color-white);
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
  padding: 0.5rem;
}
.patientname-popup-inner label {
  text-align: left;
}
.patientname-popup-inner label input {
  margin: 0;
}
.patientname-popup-inner .submit {
  margin-top: 1rem;
}
.patientname-popup-inner .submit button {
  width: 100%;
  white-space: nowrap;
}
.patientname-popup-inner .submit small {
  display: block;
  text-align: center;
  line-height: 1.2;
  margin-top: 1rem;
}

.patientname-popup-inner .soniopedia-validation-helper.error {
 font-weight: bold;
}
