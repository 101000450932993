.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.forgot-pwd.error {
    color: var(--color-red);
}

.forgot-pwd..success {
    color: var(--color-green);
}

.go-back-to-login {
    padding: 1rem;
    font-size: var(--font-size-large);
}
