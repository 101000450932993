.examination-patient-name-validation {
  margin: 0.7rem;
  text-align: center;
}
.examination-patient-name-validation .text-input > label {
  text-align: left;
}
.examination-patient-name-validation .actions button{
  margin: 0.5rem;
}
.examination-patient-name-validation-header {
  margin-top: 0.7rem;
  text-align: center;
}
