.select-input {
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 1;
  min-width: 2em;
  max-width: 100%;
}
.select-input.open {
  z-index: 1000;
}
.select-input > label {
  text-align: center;
  display: block;
  margin-bottom: .3em;
  padding: 0 0.8rem;
}
.select-input > .select-input-current {
  display: block;
  position:relative;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  border: var(--border-thick) var(--color-xxlight-grey);
}
.select-input.no-drop-down-icon > .select-input-current {
  padding-right: 0.8em !important;
}
.select-input > .select-input-current > .icon.down {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5em;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  z-index: 10;
}
.select-input:not(.no-drop-down-icon) > .select-input-current > .icon {
  margin-right: 0.25rem;
}

.select-input-options {
  position: absolute;
  z-index: 100001;
  top: 0;
  left: 0;
  height: auto;
  max-height: 0;
  white-space: nowrap;
  font-size: var(--font-size-small);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
  padding: 0.5em 0;
  background: var(--color-white);
  overflow: auto;
  scrollbar-width: auto;
}
.select-input-options.font-size-medium {
  font-size: var(--font-size-medium);
}
.select-input-options.font-size-large {
  font-size: var(--font-size-large);
}

.select-input .select-input-options {
  overflow: hidden;
  display: none;
}
.select-input.closed .select-input-options {
  display: none;
}
.select-input-options label {
  display: block;
  padding: 0.5em 0.8em;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  background: var(--color-white);
  min-height: 2em;
}
.select-input-options .option {
  display: block;
  background: var(--color-xlight-grey);
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.select-input-options .option.disabled {
  cursor: default;
  opacity: 0.5;
}
.select-input-options .option:last-of-type {
  border-bottom: 0;
}
.select-input-options.has-separators .option {
  border-bottom: 0;
}
.select-input-options .option.searchbar {
  padding: 0 1em 0.5em;
}
.select-input-options :is(.option-recents, .option-most-used) {
  border-bottom: var(--border) var(--color-black) !important;
}
.select-input-options .option.separator {
  padding: 1em 0;
  background-color: var(--color-white);
}
.select-input-options .option.separator::after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
}
  .select-input-options .option label:hover {
  color: var(--color-light-blue);
}
.select-input-options .option.selected label {
  background: var(--color-blue);
  color: var(--color-white);
}

.select-input-options .option.heading {
  padding: 0 1em;
  background-color: var(--color-white);
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.select-input-options .icon {
  margin-right:.5em;
}

.select-input label > input {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.select-input-options input {
  display: none;
}
.select-input-options .searchbar input {
  display: block;
}

.select-input .select-input-current > .icon.down {
  transition: transform .3s ease-in-out;
  transform: rotate(0);
}
.select-input.open .select-input-current > .icon.down {
  transform: rotate(180deg);
}

.select-input.padding-no .select-input-current {
  padding: 0;
}
.select-input.padding-medium .select-input-current {
  padding: 0.35em 2em 0.35em 0.8em;
}
.select-input.padding-big .select-input-current {
  padding: 0.8em 1.2em !important;
}


@media (any-pointer:coarse) {
  .select-input-options {
    font-size: var(--font-size-large);
  }
  .select-input-options .option.heading {
    font-size: var(--font-size-medium);
    margin: 0.5em 0.1em !important;
  }
}


/* theme common-white */
.select-input.theme-common-white > .select-input-current,
.select-input.theme-common-white > .select-input-current > .icon.down {
  background: var(--color-common-white);
  border-color: var(--color-common-white);
  color: var(--color-common-blue);
}
.select-input-options.theme-common-white {
  background: var(--color-common-white);
}
.select-input-options.theme-common-white .option,
.select-input-options.theme-common-white label {
  background: var(--color-common-white);
  color: var(--color-common-blue);
  border-color: var(--color-common-xlight-grey);
}
.select-input-options.theme-common-white .option.selected label {
  background: var(--color-common-blue);
  color: var(--color-common-white);
}

/* theme black */
.select-input.theme-white > .select-input-current,
.select-input.theme-white > .select-input-current > .icon.down {
  background: var(--color-white);
  border-color: var(--color-common-white);
  color: var(--color-blue);
}

.select-input-options.theme-white {
  background: var(--color-white);
}
.select-input-options.theme-white .option,
.select-input-options.theme-white label {
  background: var(--color-white);
  border-color: var(--color-xlight-grey);
}
.select-input-options.theme-white .option.selected label {
  background: var(--color-blue);
  color: var(--color-white);
}

/* theme gray */
.select-input.theme-grey > .select-input-current,
.select-input.theme-grey > .select-input-current > .icon.down {
  background: var(--color-xlight-grey);
  border-color: var(--color-xlight-grey);
  color: var(--color-blue);
}

/* theme blue */
.select-input.theme-blue > .select-input-current,
.select-input.theme-blue > .select-input-current > .icon.down {
  background: var(--color-blue);
  border-color: var(--color-blue);
  color: var(--color-white);
}

/* theme inline */
.select-input.theme-inline {
  color: inherit;
}
.select-input.theme-inline .select-input-current{
  background: transparent;
}

.select-input[data-variant="outline"] > .select-input-current {
  background: transparent !important;
  border: var(--border-thick) var(--color-blue);
}

/* disabled */
.select-input[data-disabled=true] > .select-input-current {
  cursor: default;
  background: var(--color-xlight-grey);
  border-color: var(--color-grey) !important;
  color: var(--color-grey) !important;
}
