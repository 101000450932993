.examination-sharing-flow-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0.5rem 0.8rem 1.2rem;
  height: 3.8rem;

  background-color: var(--color-xxlight-grey);
  z-index: 100;
}

.examination-sharing-flow-top-bar .group {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.examination-sharing-flow-top-bar .group.buttons {
  font-size: var(--font-size-small);
}

.examination-sharing-flow-top-bar .group.search {
  margin-inline: 0.5rem;
}
.examination-sharing-flow-top-bar
  .group.search
  .examination-live-searchbar
  .examination-sign-search-bar
  .sign-search-bar
  input,
.examination-sharing-flow-top-bar .group.search .global-search-bar input {
  background-color: var(--color-white);
}
.examination-sharing-flow-top-bar .group.search .global-search-bar ul,
.examination-sharing-flow-top-bar
  .group.search
  .examination-live-searchbar
  .examination-sign-search-bar
  > ul {
  width: 56vw;
}

.examination-sharing-flow-top-bar .group.params .feedback {
  font-size: var(--font-size-small);
  white-space: nowrap;
}
.examination-sharing-flow-top-bar .group.params .feedback .icon {
  border-radius: var(--border-radius-large);
  background-color: var(--color-light-grey);
  color: var(--color-white);
}
.examination-sharing-flow-top-bar
.group.params
.exam-preset-select-container
.select-input {
  display: block;
}

.examination-sharing-flow-top-bar
  .group.params
  .new-exam-buttons-container
  .button-group
  .option {
  cursor: auto;
}

/* shrink buttons when protocol is expanded or when filter feedback is shown */
@media (max-width: 1280px) {
  .examination-sharing-flow-top-bar
    .group.params:has(div.feedback .icon)
    ~ .group.buttons.buttons
    button
    span.icon,
  .examination-sharing-flow-top-bar
    .group.params:has(div.button-group div.option:not(.selected))
    ~ .group.buttons.buttons
    button
    span.icon {
    margin-right: -0.3rem;
  }
  .examination-sharing-flow-top-bar
    .group.params:has(div.feedback .icon)
    ~ .group.buttons.buttons
    button
    span:not(.icon),
  .examination-sharing-flow-top-bar
    .group.params:has(div.button-group div.option:not(.selected))
    ~ .group.buttons.buttons
    button
    span:not(.icon) {
    display: none;
  }
}

/* shrink buttons when search bar present and when protocol is expanded or when filter feedback is shown */
@media (min-width: 1281px) and (max-width: 1600px) {
  .examination-sharing-flow-top-bar
    .group.params:has(div.feedback .icon)
    ~ .group.search
    ~ .group.buttons.buttons
    button
    span.icon,
  .examination-sharing-flow-top-bar
    .group.params:has(div.button-group div.option:not(.selected))
    ~ .group.search
    ~ .group.buttons.buttons
    button
    span.icon {
    margin-right: -0.3rem;
  }
  .examination-sharing-flow-top-bar
    .group.params:has(div.feedback .icon)
    ~ .group.search
    ~ .group.buttons.buttons
    button
    span:not(.icon),
  .examination-sharing-flow-top-bar
    .group.params:has(div.button-group div.option:not(.selected))
    ~ .group.search
    ~ .group.buttons.buttons
    button
    span:not(.icon) {
    display: none;
  }
}

/* shrink filter feedback when protocol is expanded */
.examination-sharing-flow-top-bar
  .new-exam-buttons-container:has(div.button-group div.option:not(.selected))
  ~ .feedback
  span {
  display: none;
}
.examination-sharing-flow-top-bar
  .new-exam-buttons-container:has(div.button-group div.option:not(.selected))
  ~ .feedback
  span.icon {
  display: inline-block;
}

/* expanding trimester button to have a border*/
.button-group:has(div.option:last-child:not(.selected))
  + .new-exam-buttons-expand {
  border-left: var(--border-size) solid var(--color-grey);
}
