.slide-browser-dt-container {
  position: fixed;
  top: 0.5rem;
  left: 4rem;
  z-index: 10000;
  color: var(--color-black);
  background: var(--color-white);
  width: calc(100% - 4.5rem);
  border: var(--border) var(--color-xxlight-grey);
  box-shadow: var(--common-box-shadow);
  height: calc(100% - 1rem);
  overflow: auto;
}
.slide-browser-dt-container > h2 {
  position: sticky;
  top: 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
  margin: 0;
  padding: 1rem;
  font-size: var(--font-size-medium);
  z-index: 1000;
  background-color: var(--color-white);
}
.slide-browser-dt-container .slide-browser-dt-search {
  position: fixed;
  top: 0.5rem;
  right: 3rem;
  padding: 0.5rem;
  z-index: 1010;
}
.slide-browser-dt-container .slide-browser-dt-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1020;
  font-size: var(--font-size-xlarge);
  padding: 1.1rem;
  cursor: pointer;
}
.slide-browser-dt-container .slide-browser-dt-close:hover {
  color: var(--color-light-blue);
}
.slide-browser-dt-container ul {
  padding: 0;
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
.slide-browser-dt-container ul li {
  position: relative;
  border-radius: var(--border-radius-small);
  background: var(--color-xxxlight-grey);
  cursor: pointer;
}
.slide-browser-dt-container ul li.completed {
  background: var(--color-white);
}
.slide-browser-dt-container ul li.completed .slide-browser-dt-name {
  opacity: 0.4;
}
.slide-browser-dt-container ul li.selected {
  background: var(--color-xxlight-grey) !important;
  opacity: 1 !important;
}
.slide-browser-dt-container ul li:hover {
  opacity: 1 !important;
}
