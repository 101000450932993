.exam-report-container {
  padding: 3rem;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
}
.exam-report-container.exam-report-loading {
  display: flex;
  font-size: var(--font-size-xxxlarge);
  justify-content: center;
  align-items: center;
  height: 100%;
}
.exam-report-container.exam-report-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.exam-report-template .not-visible > :not(.inline-editing-not-visible-mask) {
  opacity: 0.6;
}

.exam-report-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100svh;
}

.template-section {
  min-height: 101svh;
  padding: 3rem 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
}

.exam-report-content {
  overflow: auto;
  flex-grow: 2;
  position: relative;
  scrollbar-width: auto;
  scrollbar-color: var(--color-grey) var(--color-xxxlight-grey);
  overflow-x: hidden;
}
.exam-report-wrapper.frozen .exam-report-content .exam-report-template {
  pointer-events: none !important;
}

.exam-report-frozen-warning {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 10000;
}
.exam-report-frozen-warning div {
  position: absolute;
  cursor: pointer;
  height: 100svh;
  width: 100%;
}

.exam-report-options {
  font-size: var(--font-size-small);
  padding: 1rem;
  border-top: var(--border) var(--color-xlight-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.exam-report-options-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.exam-report-options-column .exam-report-status {
  line-height: var(--font-size-small);
}
.exam-report-options-column .exam-report-status .exam-completed-status-warning {
  color: var(--color-ocra);
  display: flex;
  gap: 0.2em;
  align-items: center;
}
.exam-report-options-column > div {
  position: relative;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exam-report-options-column > .select-input > .select-input-current {
  line-height: inherit;
}

.exam-report-options_create-new-flash-report {
  padding: 1rem;
  font-size: var(--font-size-small);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.exam-report-container .columns {
  display: flex;
  align-items: flex-start;
  justify-items: stretch;
  gap: 4rem;
  width: 100%;
  margin-bottom: 4rem;
}

.exam-report-container .column {
  flex-grow: 2;
  flex: 1;
}

.exam-report-container .field {
  margin: 0.1rem 0;
}

.exam-report-container .field label {
  min-width: 40%;
  display: inline-flex;
  max-width: 50%;
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
  color: var(--color-black);
  font-weight: normal;
}

.exam-report-container .field label::after {
  content: "";
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}

.exam-report-container .field.is-risky {
  color: var(--color-ocra);
}

.exam-report-container .field .notes {
  white-space: nowrap;
  font-size: var(--font-size-xsmall);
}

.exam-report-container .field>span {
  display: inline-block;
  margin-left: 0.4em;
  max-width: calc(60% - 0.4em);
}

.exam-report-container .field .raw-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 .2em;
  margin: 0;
}

.exam-report-container>section:not(.template-section) {
  margin-bottom: 4rem;
}

.exam-report-container>section:not(.template-section):last-of-type {
  margin-bottom: 0;
}

.exam-report-container .measurement.at-risk {
  color: var(--color-ocra);
}

.exam-report-container .measurement.at-risk {
  color: var(--color-ocra);
}

.exam-report-measurements {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: var(--font-size-small);
}

.exam-report-measurements .measurement {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 1rem;
}

.exam-report-measurements .measurement>* {
  align-content: center;
}

.exam-report-measurements .measurement .measurement-label {
  color: var(--color-black);
  line-height: 1;
}

.exam-report-measurements .measurement .measurement-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exam-report-measurements .measurement .measurement-unit {
  margin-left: 0.5em;
}

.exam-report-measurements .measurement .measurement-percentile {
  white-space: nowrap;
}

.exam-report-measurements .measurement .measurement-percentile-value {
  display: inline-block;
  min-width: 2rem;
  text-align: right;
}

.exam-report-measurements .measurement .growth-standard {
  display: inline-block;
  font-size: 0.8em;
  padding: 0 0.5em;
  border: var(--border) var(--color-xxlight-grey);
  color: var(--color-grey);
  border-radius: var(--border-radius-small);
  margin-left: 0.5em;
}

.exam-report-measurements .measurement .measurement-graph {
  margin-top: 0.5rem;
}


/* REPORT TEMPLATE */
.exam-report-template {
  padding: 3rem;
  --line-height: 1.2;
}

/* header */
.exam-report-template div.header,
.exam-report-template header,
.exam-report-template footer {
  display: none;
}

/* block */
.exam-report-template .block {
  position: relative;
}

.exam-report-template .block.size-1 {
  font-size: var(--font-size-xlarge);
  line-height: var(--line-height);
}

.exam-report-template .block.size-2 {
  font-size: var(--font-size-large);
  line-height: var(--line-height);
}

.exam-report-template .block.size-3 {
  font-size: var(--font-size-medium);
  line-height: var(--line-height);
}

.exam-report-template .block.size-4 {
  font-size: var(--font-size-small);
  line-height: var(--line-height);
}

.exam-report-template .block.size-5 {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height);
}

.exam-report-template .block.size-5 {
  font-size: var(--font-size-xxsmall);
  line-height: var(--line-height);
}

.exam-report-template .block[data-layout=columns] {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.exam-report-template .block[data-layout=columns]>* {
  flex: 1;
}

.exam-report-template .block.has-background {
  padding: 1rem;
}

.exam-report-template .block.is-outlined {
  padding: 1rem;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
}


/* checklist v1 */
.exam-report-template .checklist_v1 {
  position: relative;
  z-index: 10;
}
.exam-report-template .checklist_v1:hover {
  z-index: 100;
}
.exam-report-template .checklist_v1 > .exam-report-editing-options {
  position: absolute;
  display: none;
  top: -3rem;
  height: calc(100% + 4rem);
  left: -1rem;
  width: calc(100% + 2rem);
  padding: 0.2rem;
  background: var(--color-xxxlight-grey) !important;
  border: var(--border-thick) var(--color-light-grey);
  font-size: var(--font-size-small);
}
.exam-report-template .checklist_v1:hover > .exam-report-editing-options {
  display: block;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}
.exam-report-template .checklist_v1 > .exam-report-editing-options > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.3em;
  padding-bottom: 0.3rem;
  border-bottom: var(--border) var(--color-xlight-grey);
}

.exam-report-template .checklist_v1 span {
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.exam-report-template .checklist_v1 span:hover {
  z-index: 100;
}

.exam-report-template .checklist_v1 span.unusual {
  color: var(--color-ocra);
}

.exam-report-template .checklist_v1 span.non_visualized {
  color: var(--color-grey);
}



/* checklist v2 */
.exam-report-template .checklist_v2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.exam-report-template .checklist_v2 .checklist_comment {
  margin-left: 0.3em;
}
.exam-report-template .checklist_v2 .checklist_group > div {
  cursor: pointer;
}
.exam-report-template .checklist_v2 .checklist_group .nothing-to-display {
  opacity: 0.5;
}

/* headings */
.exam-report-template :where(h1, h2, h3, h4, h5, h6) {
  color: var(--color-black);
  line-height: 1.2em;
  margin: 0;
}


/* images */
.exam-report-template .images {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.exam-report-template .images img {
  width: 100%;
  height: auto;
}

/* label */
.exam-report-template .label {
  display: flex;
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
  color: var(--color-black);
  font-weight: normal;
}

.exam-report-template .label::after {
  content: "";
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}
.exam-report-template .is-inline > .label::after {
  display: none;
}

.exam-report-template .highlight-field {
  border-radius: 0.1rem;
  color: var(--color-common-blue);
  background: var(--color-common-white);
  box-shadow: -0.1em -0.1em 0 0.2rem var(--color-common-white);
  display: flex !important;
}
.exam-report-template .highlight-field .highlight-field_icon {
  color: var(--color-common-white);
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.4));
}
.exam-report-template .highlight-field .highlight-field_icon.selected {
  color: var(--color-common-blue);
  box-shadow: none;
}

/* dropdown */
.exam-report-template .dropdown-wrapper {
  display: inline;
}
.exam-report-template .dropdown-wrapper .highlight-field {
  display: inline-flex;
}

.exam-report-template .dropdown-wrapper.full-width>span {
  display: block;
}

/* content */
.exam-report-template :is(.content-wrapper, .dropdown-wrapper) {
  display: flex;
  gap: 2rem;
  position: relative;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper).is-inline {
  display: inline-flex;
  gap: 0.5em;
  margin-right: 1.5em;
}
.exam-report-template .textblock-wrapper.is-inline {
  gap: 0.5em;
  margin-right: 1.5em;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper, .textblock-wrapper)>span {
  position: relative;
  display: block;
  line-height: 1;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper) .content-text {
  display: inline-block;
  vertical-align: baseline;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper) .content-comment {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: baseline;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper, .textblock-wrapper) .label {
  min-width: 30%;
}
.exam-report-template :is(.content-wrapper, .dropdown-wrapper, .textblock-wrapper).is-inline .label {
  min-width: 0;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper, .textblock-wrapper).align-right .label {
  flex-grow: 2;
}

.exam-report-template :is(.content-wrapper, .dropdown-wrapper).align-center>span {
  text-align: center;
  flex-grow: 2;
}


.exam-report-template .required-error {
  outline: var(--border-thick) var(--color-xlight-red);
  border-radius: var(--border-radius-small);
}

.exam-report-template .exam-report-coding .required-error {
  outline: none;
}

/* biometry table */
.exam-report-template .biometry-item {
  position: relative;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 0.5fr 1fr;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem 0;
  align-items: center;
}

.exam-report-template .biometry-item.is-editable {
  cursor: pointer;
}

.exam-report-template .biometry-item.column-heading {
  font-size: var(--font-size-xsmall);
  text-align: center;
  padding: 0;
  gap: var(--border-size);
}

.exam-report-template .biometry-item.column-heading>* {
  position: relative;
  background: var(--color-xlight-blue-40);
}

.exam-report-template .biometry-item .biometry-item-fetus-number {
  display: inline-block;
  margin-right: 0.5em;
  color: var(--color-light-grey);
  min-width: 1em;
  text-align: right;
}

.exam-report-template .biometry-item .biometry-item-ga {
  text-align: center;
}

.exam-report-template .biometry-item .biometry-item-comment {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
}


.exam-report-template .biometry-item .biometry-item-value {
  display: flex;
  gap: 0.5em;
  justify-content: center;
}
.exam-report-template .biometry-item .biometry-item-value[data-multiunit=true] {
  gap: 0.5em;
}
.exam-report-template .biometry-item .biometry-item-value[data-multiunit=true] > span {
  display: flex;
  gap: 0.3em;
}

.exam-report-template .biometry-item .biometry-item-value .value {
  text-align: right;
  flex: 1;
}

.exam-report-template .biometry-item .biometry-item-value .unit {
  flex: 1;
}

.exam-report-template .biometry-item .biometry-item-percentile {
  text-align: right;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.exam-report-template .biometry-item .biometry-item-percentile>* {
  flex: 2;
}

.exam-report-template .biometry-item .biometry-item-percentile-graph {
  flex: 5 5;
  max-width: 10rem;
}

.exam-report-template .biometry-item .biometry-item-reference-value {
  font-size: var(--font-size-xsmall);
  /* line-height: var(--font-size-xsmall); */
  color: var(--color-grey);
  flex: 3;
}

/* GA table */
.exam-report-template .ga-table .ga-item {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr 1fr 0.5fr 0fr;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem 0;
  align-items: center;
  text-align: left;
  gap: var(--border-size);
}

.exam-report-template .ga-table .ga-item > div {
  position: relative;
}

.exam-report-template .ga-table .ga-item.show-fetus {
  grid-template-columns: 0.5fr 2fr 1.5fr 2fr 1.5fr 1.1fr 1.1fr 0.25fr 0fr;
}

.exam-report-template .ga-table .ga-item.show-fetus .double-col {
  grid-column: 1 / 3;
}

.exam-report-template .ga-table .ga-item.ga-item-assigned-row .ga-item-assigned-details {
  grid-column: 3 / 5;
}

.exam-report-template .ga-table .ga-item.ga-item-assigned-row.show-fetus .ga-item-assigned-details {
  grid-column: 4 / 6;
}
.exam-report-template .ga-table .ga-item .ga-item-non-editable-details {
  grid-column: 3 / 5;
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  justify-content: space-between;
}

.exam-report-template .ga-table .ga-item.show-fetus .ga-item-non-editable-details {
  grid-column: 4 / 6;
}
.exam-report-template .ga-table .ga-item .ga-item-non-editable-details div {
  flex: 1;
  justify-content: space-between;
  margin: auto;
}

.ga-item:not(.column-heading):hover {
  outline: var(--border) var(--color-light-grey);
}

.exam-report-template .ga-item .ga-item-fetus-number {
  display: inline-block;
  margin-right: 0.5em;
  color: var(--color-light-grey);
  min-width: 1em;
  padding-right: 1.5rem;
  padding-left: 0.25rem;
}

.exam-report-template .ga-table .ga-item.assigned {
  font-weight: bold;
}

.exam-report-template .ga-table .ga-item.add-row>div {
  grid-column: 1 / 8;
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size-large);
  cursor: pointer;
}

.exam-report-template .ga-table .ga-item.add-row>div>.icon {
  cursor: pointer;
}

.exam-report-template .ga-table .ga-item.is-editable {
  cursor: pointer;
}

.exam-report-template .ga-table .ga-item.column-heading {
  font-size: var(--font-size-xsmall);
  text-align: center;
  padding: 0;
  gap: var(--border-size);
}

.exam-report-template .ga-table .ga-item.column-heading>* {
  position: relative;
  background: var(--color-xlight-blue-40);
}

.exam-report-template .ga-table .ga-item.column-heading > :first-child {
  text-align: left;
  padding-left: 0.4rem;
}

.exam-report-template .ga-table .ga-item .ga-item-comment {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
}

.exam-report-template .ga-table .ga-item .ga-item-center {
  display: flex;
  justify-content: center;
  gap: 0.3em;
}

.exam-report-template .ga-table .ga-item .ga-item-extra-info-text {
  font-size: var(--font-size-xsmall);

}

.exam-report-template .ga-table .ga-item .exam-report-editing-options {
  position: absolute;
  left: calc(100%);
  display: none;
}

.exam-report-template .ga-table .ga-item:hover .exam-report-editing-options {
  display: flex;
  flex-direction: column;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}

.exam-report-template .ga-table .ga-item .exam-report-editing-options>div {
  padding: 0.3em 0.5em;
  cursor: pointer;
  border-left: var(--border) var(--color-white);
}

.exam-report-template .ga-table .ga-item .exam-report-editing-options>div:first-child {
  border-left: 0;
}

.exam-report-template .ga-item .ga-item-assign {
  cursor: pointer;
}
.exam-report-template .ga-item .ga-item-hint-balloon {
  position: absolute;
  bottom: 120%;
  left: 10%;
  z-index: 100;
  color: var(--color-common-white);
  background: var(--color-common-blue);
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  white-space: nowrap;
  transform: translateX(-50%);
  padding: 0.4em 0.5em;
  border-radius: var(--border-radius-small);
  animation: ga-item-hint-balloon-bounce 1s ease-in-out infinite;
  pointer-events: none;
}
.exam-report-template .ga-item .ga-item-hint-balloon::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.6em solid transparent;
  border-top-color: var(--color-common-blue);
  translate: -50% 0;
}

@keyframes ga-item-hint-balloon-bounce {
  0% { translate: 0 0; }
  40% { translate: 0 -0.2em; }
  50% { translate: 0 -0.2em; }
  80% { translate: 0 0; }
}


/* Fetal Growth table */
.exam-report-template .fetal-growth-table-container {
  position: relative;
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item {
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem 0;
  align-items: center;
  text-align: left;
  gap: var(--border-size);
  font-size: var(--font-size-small);
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item.column-heading {
  font-size: var(--font-size-xsmall);
  text-align: center;
  padding: 0;
  gap: var(--border-size);
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item.column-heading>* {
  position: relative;
  background: var(--color-xlight-blue-40);
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item .fetal-growth-cell {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 1rem 0 0.5rem;
  border-right: var(--border) var(--color-xxlight-grey);
  overflow: hidden;
  min-height: 1.275rem;
  white-space: nowrap;
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item .fetal-growth-cell-seperator {
  margin: 0 0.1rem 0 0.075rem;
  color: var(--color-xxlight-grey);
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item .fetal-growth-cell-percentile {
  font-style: italic;
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item .fetal-growth-cell:last-of-type {
  border: none
}

.exam-report-template .fetal-growth .fetal-growth-fetus-label {
  color: var(--color-black);
  line-height: 1;
  margin-bottom: 0.5rem;
}

.exam-report-template .fetal-growth {
  margin: 0.75rem 0;
  position: relative;
}

.exam-report-template .fetal-growth .fetal-growth-report-options-container {
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
}

.exam-report-template .fetal-growth-title {
  margin-bottom: -1rem
}

.exam-report-template .fetal-growth-title.not-visible {
  opacity: 0.6;
}

.exam-report-template .fetal-growth .fetal-growth-report-options-container .exam-report-editing-options {
  display: none;
}

.exam-report-template .fetal-growth:hover .exam-report-editing-options,
.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item:hover .exam-report-editing-options {
  display: flex;
  flex-direction: column;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item .exam-report-editing-options {
  position: absolute;
  left: calc(100%);
  width: 30px;
  display: none;
}

.exam-report-template .fetal-growth .fetal-growth-table .fetal-growth-item:hover .exam-report-editing-options {
  display: flex;
}

.exam-report-template .fetal-growth .exam-report-editing-options>div {
  padding: 0.3em 0.5em;
  cursor: pointer;
  border-left: var(--border) var(--color-white);
}
/* biometry curve */
.exam-report-template .measurement-curve {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.exam-report-template .measurement-curve.not-visible>* {
  text-decoration: none;
}

.exam-report-template .measurement-curve.is-editable {
  cursor: pointer;
}

.exam-report-template .measurement-curve>* {
  align-content: center;
}

.exam-report-template .measurement-curve .measurement-curve-label {
  color: var(--color-black);
  line-height: 1;
}

.exam-report-template .measurement-curve .measurement-curve-comment {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
}

.exam-report-template .measurement-curve .measurement-curve-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-small);
}

.exam-report-template .measurement-curve .measurement-curve-unit {
  margin-left: 0.5em;
}

.exam-report-template .measurement-curve .measurement-curve-percentile {
  white-space: nowrap;
}

.exam-report-template .measurement-curve .measurement-curve-percentile-value {
  display: inline-block;
  min-width: 2rem;
  text-align: right;
}

.exam-report-template .measurement-curve .measurement-curve-growth-standard {
  display: inline-block;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0 0.5em;
  border: var(--border) var(--color-xxlight-grey);
  color: var(--color-grey);
  border-radius: var(--border-radius-small);
  margin-left: 0.5em;
}

.exam-report-template .measurement-curve.exam-report-editing-field .exam-report-editing-options {
  position: absolute;
  left: calc(100% - 0.1rem);

}


.exam-report-template .measurement .measurement-graph {
  margin-top: 0.5rem;
}


/* graphs */
.exam-report-template .graphs-wrapper .graphs-line {
  margin-bottom: 3rem;
}
.exam-report-template .graphs-wrapper > .graphs-line:last-of-type {
  margin-bottom: 0;
}


/* medical history */
.exam-report-template .medical-history .medical-history-item {
  display: flex;
  gap: 2rem;
}

.exam-report-template .medical-history .medical-history-item.at-risk {
  color: var(--color-ocra);
}

.exam-report-template .medical-history .medical-history-item .teratogenic {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
  text-transform: uppercase;
}

.exam-report-template .medical-history .label {
  min-width: 30%;
}

/* spacer */
.exam-report-template .spacer {
  display: block;
  position: relative;
}

.exam-report-template .spacer.size-1 {
  height: 5rem;
}

.exam-report-template .spacer.size-2 {
  height: 3rem;
}

.exam-report-template .spacer.size-3 {
  height: 2rem;
}

.exam-report-template .spacer.size-4 {
  height: 1rem;
}

.exam-report-template .spacer.size-5 {
  height: 0.5rem;
}

.spacer.style-line::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: var(--border-size);
  background: var(--color-xlight-grey-40);
}

/* textblock */
.exam-report-template .textblock-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  gap: 2rem;
}

.exam-report-template .textblock-wrapper:not(.is-inline)>span {
  flex-grow: 2;
}

.exam-report-template .textblock-wrapper .textblock-text {
  white-space: pre-wrap;
}

.exam-report-template .textblock-wrapper.is-editing {
  z-index: 100;
}

.exam-report-template .textblock-wrapper .textblock-text .required {
  position: absolute;
  top: 0;
  right: 0;
}

.exam-report-template .textblock>.textblock-comment {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
  margin: 0.2rem 0 0;
}

.exam-report-template .exam-report-editing-field.textblock-wrapper .exam-report-editing-options {
  flex-direction: column;
}

.exam-report-template .exam-report-editing-field.textblock-wrapper .exam-report-editing-options>div {
  border-left: 0;
  border-top: var(--border) var(--color-white);
}

/* private */
.exam-report-template .private {
  padding: 0 1rem;
  border: var(--border-dashed-thick) var(--color-common-blue);
  position: relative;
}

.exam-report-template .private .private-label {
  position: sticky;
  left: 0;
  top: 0;
  line-height: var(--font-size-xxsmall);
  display: block;
}

.exam-report-template .private .private-label span {
  font-size: var(--font-size-xxsmall);
  text-transform: uppercase;
  background-color: var(--color-common-blue);
  color: var(--color-common-black);
  padding: 0.6em 1em;
  margin-left: calc(-1rem - var(--border-thick-size));
}

/* image */
.exam-report-template .custom-image.align-right {
  text-align: right;
  display: block;
}

.exam-report-template .custom-image.align-left {
  text-align: left;
  display: block;
}

.exam-report-template .custom-image.align-center {
  text-align: center;
  display: block;
}

/* page breaks */
.exam-report-template .page-break {
  height: 3rem;
}

/* table */
.exam-report-template .table-wrapper.required-error table {
  outline: var(--border-thick) var(--color-xlight-red);
  border-radius: var(--border-radius-small);
}
.exam-report-template .table-wrapper table {
  width: 100%;
  border: var(--border) var(--color-xlight-grey);
}
.exam-report-template .table-wrapper table tbody.is-dragging tr td > * {
  pointer-events: none;
}
.exam-report-template .table-wrapper {
  line-height: 1.2;
}
.exam-report-template .table-wrapper .table-label {
  padding: 0.2rem 0.5rem;
  border: var(--border) var(--color-xlight-grey);
  border-bottom: 0;
  font-size: var(--font-size-small);
  color: var(--color-black);
  background: var(--color-xxxlight-grey);
}

.exam-report-template .table-wrapper table th {
  border-bottom: var(--border) var(--color-xlight-grey);
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  text-align: left;
  padding: 0.5rem;
}

.exam-report-template .table-wrapper table tr.drag-overing [contenteditable="true"] {
  pointer-events: none;
}
.exam-report-template .table-wrapper table tr.dragged-line {
  background-color: var(--color-xlight-grey-40);
}
.exam-report-template .table-wrapper table tr.dragged-line > * {
  opacity: 0;
}

.exam-report-template .table-wrapper table td {
  border-bottom: var(--border) var(--color-xxxlight-grey);
  padding: 0.5em;
}
.exam-report-template .table-wrapper table td.draggable {
  background: var(--color-xxlight-grey-40);
  color: var(--color-xlight-grey);
  padding: 0;
  vertical-align: middle;
  line-height: 0;
  width: 0;
  padding: 0.2em;
  text-align: center;
  position: relative;
}
.exam-report-template .table-wrapper table td.draggable:hover {
  color: var(--color-black);
  background: var(--color-xxlight-grey-80);
  cursor: grab;
}
.exam-report-template .table-wrapper table td.draggable > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.exam-report-template .table-wrapper table td.empty-warning {
  text-align: center;
  font-size: var(--font-size-small);
  padding: 1rem !important;
  color: var(--color-ocra);
}
.exam-report-template .table-wrapper table .table_cta {
  width: 0;
}
.exam-report-template .table-wrapper table tfoot td {
  border-top: var(--border) var(--color-xxlight-grey);
}

.exam-report-template .table-wrapper table .exam-report-editing-options {
  display: none !important;
}

/* generic */
.exam-report-template .required {
  color: var(--color-ocra);
  margin-left: 0.2em;
}

/* EDITOR */
.exam-report-template .exam-report-editing-field {
  position: relative;
}

.exam-report-template .exam-report-editing-field:hover {
  z-index: 100;
}

.exam-report-template .exam-report-editing-field .exam-report-editing-options {
  position: absolute;
  top: -0.3rem;
  left: calc(100%);
  display: none;
}

.exam-report-template .exam-report-editing-field:is(.dropdown-wrapper, .checklist-item) .exam-report-editing-options {
  left: calc(100% + 1.5em);
}
.exam-report-template .exam-report-editing-field.dropdown-wrapper.is-required .exam-report-editing-options {
  left: calc(100% + 0.8em);
}

.exam-report-template .exam-report-editing-field:hover .exam-report-editing-options {
  display: flex;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}

.exam-report-template .exam-report-editing-field .exam-report-editing-options>div {
  padding: 0.3em 0.5em;
  cursor: pointer;
  border-left: var(--border) var(--color-white);
}

.exam-report-template .exam-report-editing-field .exam-report-editing-options>div:first-child {
  border-left: 0;
}

.exam-report-template .exam-report-editing-comment {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-grey);
}

.exam-report-edit-field {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 15rem;
  background: var(--color-xlight-grey);
  border-radius: var(--border-radius-small);
  padding: 0.5rem;
  transform: translate(-50%, -50%);
  z-index: 10000;
  container: card / inline-size;
}

.exam-report-edit-field .value {
  position: relative;
  display: flex;
  gap: 1.5rem;
}

.exam-report-edit-field .value>.visibility {
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--font-size-large);
}

.exam-report-edit-field .comment:not(.icon) {
  font-size: var(--font-size-xsmall);
  margin-top: 0.5rem;
}

.exam-report-edit-field .text-input.variant-underline input {
  border-color: var(--color-grey) !important;
}

.exam-report-edit-field.biometry>.measurement-label {
  flex-grow: 2;
}

.exam-report-edit-field.biometry>.visibility {
  position: relative;
}

@container (max-width: 45rem) {
  .exam-report-edit-field.biometry .value {
    flex-direction: column;
  }
}

.exam-report-edit-field.dropdown .value {
  gap: 0.5rem;
}

.exam-report-edit-field.dropdown .visibility {
  position: relative;
}

.exam-report-edit-field.ga .value-weeks-days {
  display: flex;
  flex-direction: column;
}

.exam-report-edit-field.ga .value-weeks-days .value-edit {
  margin-bottom: 0.2rem;
}

.exam-report-edit-field.ga .value-weeks-days .value-edit .value-label {
  margin-left: 0.5rem;
}

.exam-report-edit-field.textarea {
  position: absolute !important;
  top: -0.8rem;
  left: -1rem;
  width: calc(100% + 2rem);
  height: calc(100% + 1.6rem);
  min-height: calc(2em + 1rem);
  transform: none;
}

.exam-report-edit-field.textarea .value,
.exam-report-edit-field.textarea .textarea,
.exam-report-edit-field.textarea .textarea-field,
.exam-report-edit-field.textarea textarea {
  width: 100% !important;
  height: 100% !important;
  min-height: calc(1em + 1rem);
  resize: none;
}

/* Identifier biometry */
.exam-report-template .identifier-biometry-table .identifier-biometry-id {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem 0;
  align-items: center;
  gap: var(--border-size);
  text-align: center;
  margin: 0.4rem 0;
}

.exam-report-template .identifier-biometry-table .exam-report-editing-options {
  position: absolute;
  left: calc(100%);
  display: none;
}

.exam-report-template .identifier-biometry-id:hover .exam-report-editing-options {
  display: flex;
  flex-direction: column;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-shadow: var(--common-box-shadow);
}

.exam-report-template .identifier-biometry-id .exam-report-editing-options>div {
  padding: 0.3em 0.5em;
  cursor: pointer;
  border-left: var(--border) var(--color-white);
}

.exam-report-template .identifier-biometry-id .exam-report-editing-options>div:first-child {
  border-left: 0;
}
.identifier-biometry-table .identifier-biometry-id.column-heading {
  font-size: var(--font-size-xsmall);
  text-align: center;
  padding: 0;
  gap: var(--border-size);
  position: relative;
  background: var(--color-xlight-blue-40);
}

.exam-report-template .identifier-biometry.add-row>div {
  grid-column: 1 / 8;
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size-large);
  cursor: pointer;
}

.exam-report-template .identifier-biometry.add-row>div>.icon {
  cursor: pointer;
}

.exam-report-container .listening-support {
  color: var(--color-common-white);
  filter: drop-shadow(0 1px 3px rgb(0 0 0 / 0.8));
  margin-left: 0.5em;
  font-size: 1em;
}
.exam-report-container .listening-support.source {
  color: var(--color-common-blue);
  filter: none;
}
