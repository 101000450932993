.loader-inline-container {
  position: relative;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  border: var(--border-thick) transparent;
  border-top-color: var(--color-blue);
  border-radius: 50%;
  -webkit-animation: loader-inline-container-animation 1s ease-in-out infinite;
  animation: loader-inline-container-animation 1s ease-in-out infinite;
}
@keyframes loader-inline-container-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
