body {
  --section-navbar-width: 4rem;
}

.sections-nav-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-xxxlight-grey);
  font-size: var(--font-size-xsmall);
  line-height: 1;
  white-space: nowrap;
  height: 100%;
  min-width: var(--section-navbar-width);
  max-width: var(--section-navbar-width);
  min-height: 30rem;
  display: grid;
  align-content: center;
  padding: 1rem 0;
  border-right: var(--border) var(--color-xxlight-grey);
  z-index: 100;
}

.sections-nav-bar ul {
  margin: 0.7rem;
  list-style: none;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: var(--color-xxxlight-grey);
  padding: 0;
  width: 3rem;
}

.sections-nav-bar ul.expanded {
  background: var(--color-white);
  box-shadow: 0.5rem 0 0 0.7rem var(--color-white);
  z-index: 100;
}

.sections-nav-bar ul li {
  padding: 0.1rem 0.1rem 0.2rem;
  margin: 0;
  cursor: pointer;
  line-height: 1;
  display: block;
  position: relative;
}

.sections-nav-bar ul li:hover {
  color: var(--color-light-blue);
}

.sections-nav-bar ul li.marker {
  background: var(--color-blue);
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: 0.1rem;
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow-thin);
  z-index: -1;
  transform: translateY(0.1rem);
  transition: top 0.3s ease-in-out;
}

.sections-nav-bar ul li>.icon-wrapper {
  width: 2.5rem;
  height: 2.5rem;
  font-size: var(--font-size-large);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-right: 0.6rem;
  padding: 0.1rem;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  background: transparent;
}

.sections-nav-bar ul li.unusual .icon-wrapper {
  color: var(--color-white) !important;
  background: var(--color-ocra) !important;
}

.sections-nav-bar ul li.disabled {
  color: var(--color-xxlight-grey);
  cursor: default;
}

.sections-nav-bar ul li.disabled .icon-wrapper {
  background: transparent;
  color: var(--color-xxlight-grey-40) !important;
  border-color: var(--color-xxlight-grey-40);
}

.theme-dark .sections-nav-bar ul li>.icon-wrapper {
  color: var(--color-black);
}

.sections-nav-bar ul li.current>.icon-wrapper {
  color: var(--color-white);
  background: transparent;
}

.sections-nav-bar ul.expanded li>.icon-wrapper {
  background: transparent;
}

.sections-nav-bar ul li>div {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  padding-right: 0.5rem;
}

.sections-nav-bar ul li>div>b {
  font-size: 1.1em;
}

.sections-nav-bar ul li .counter {
  position: absolute;
  top: 0rem;
  left: 1.8rem;
  color: var(--color-white);
  background-color: var(--color-blue);
  font-size: var(--font-size-small);
  border-radius: 50%;
  padding: 0.10rem;
  min-width: 1.4em;
  text-align: center;
}

.sections-nav-bar ul li .risk-factor {
  position: absolute;
  top: 0.2rem;
  left: 1.8rem;
  color: var(--color-white);
  background-color: var(--color-blue);
  font-size: var(--font-size-xsmall);
  border-radius: 50%;
  padding: 0.15rem;
  box-shadow: var(--common-box-shadow-thin);
}

.sections-nav-bar.sections-nav-bar-options {
  background: var(--color-white);
  margin-left: 0.5rem;
  border: var(--border) var(--color-xxlight-grey);
  border-right: 0;
  border-top-left-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);
  width: 3.5rem;
  position: relative;
}

.sections-nav-bar.sections-nav-bar-options ul {
  margin-left: 0.4rem;
  background: var(--color-white);
  border-radius: var(--border-radius-small);
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.sections-nav-bar-options-corner-top,
.sections-nav-bar-options-corner-bottom {
  position: absolute;
  top: -1.5rem;
  left: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
}

.sections-nav-bar-options-corner-bottom {
  top: auto;
  bottom: -1.5rem;
}

.sections-nav-bar-options-corner-top::after,
.sections-nav-bar-options-corner-bottom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-xxxlight-grey);
  border: var(--border) var(--color-xxlight-grey);
  border-left: 0;
}

.sections-nav-bar-options-corner-top::after {
  border-bottom-right-radius: var(--border-radius-large);
  border-top: 0;
}

.sections-nav-bar-options-corner-bottom::after {
  border-top-right-radius: var(--border-radius-large);
  border-bottom: 0;
}

.sections-nav-bar-container.action-selected .sections-nav-bar.sections-nav-bar-options {
  background: transparent;
  opacity: 0.5;
}

.sections-nav-bar-container.action-selected .sections-nav-bar.sections-nav-bar-options ul {
  background: transparent;
}

.sections-nav-bar-container.action-selected .sections-nav-bar-options-corner-top,
.sections-nav-bar-container.action-selected .sections-nav-bar-options-corner-bottom {
  background: var(--color-xxxlight-grey);
  border-right: var(--border) var(--color-xxlight-grey);
}

.sections-nav-bar-container.action-selected .sections-nav-bar ul li.marker {
  background: transparent;
  box-shadow: none;
}

.sections-nav-bar-container.action-selected .sections-nav-bar ul li.current .icon-wrapper {
  color: var(--color-black);
}


.sections-nav-bar.sections-nav-bar-actions ul li>.icon-wrapper {
  border-radius: var(--border-radius-small);
  background: transparent;
  border-color: transparent;
}

.sections-nav-bar.sections-nav-bar-actions ul li.selected>.icon-wrapper {
  background: var(--color-xxlight-grey);
}

.sections-nav-bar.sections-nav-bar-actions li:hover .icon-wrapper {
  color: var(--color-black);
}

.sections-nav-bar-separator {
  border-bottom: var(--border) var(--color-xxlight-grey);
  margin: 1rem 0 1rem 1rem;
  width: calc(var(--section-navbar-width) - 2rem);
}

.sections-nav-bar-container.disable-actions .sections-nav-bar-actions {
  pointer-events: none;
  cursor: default;
}

.sections-nav-bar-container.disable-actions .sections-nav-bar-actions .icon-wrapper {
  color: var(--color-xxlight-grey-40);
}
