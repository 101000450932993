.publications-container ul.publications {
  padding: 0;
  margin: 0;
  list-style: none;
}
.publications-container ul.publications li {
  padding: 0;
  margin-bottom: 2rem;
}
.publications-container ul.publications li .publication-meta {
  display: flex;
  gap: 1rem;
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
  border-top: var(--border) var(--color-xlight-grey);
  margin-top: 0.4rem;
  padding-top: 0.4rem;
}
.publications-container ul.publications li .publication-meta > span:first-child {
  flex-grow: 2;
}
.publications-container ul.publications li a {
  text-decoration: underline;
  color: var(--color-light-grey);
  white-space: nowrap;
}