.examination-live-searchbar .examination-sign-search-bar > ul {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  z-index: 5;
  padding: 0;
  box-shadow: var(--box-shadow-thin);
  max-height: calc(100vh - 5rem);
  overflow: auto;
  min-width: 100%;
  border-radius: var(--border-radius-small);
  background: var(--color-white);
}
.examination-live-searchbar .examination-patient-name {
  padding: 0.3rem 1rem 0.2rem 1rem;
}
.examination-live-searchbar .examination-patient-name .icon {
  margin-right: .5em;
}
