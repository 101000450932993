.image-manipulation-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 5rem 1fr;
  justify-content: stretch;
  align-items: stretch;
  font-size: var(--font-size-small);
}

.image-manipulation-container-close {
  z-index: 101;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: xx-large;
}

.image-manipulation-canvas-container {
  position: relative;
  flex-grow: 2;
  overflow: hidden;
  background: var(--color-common-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-manipulation-canvas-wrapper {
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-manipulation-canvas-innerWrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.image-manipulation-canvas {
  image-rendering: optimizeSpeed;             /*                     */
  image-rendering: -moz-crisp-edges;          /* Firefox             */
  image-rendering: -o-crisp-edges;            /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: pixelated;                 /* Chrome as of 2019   */
  image-rendering: optimize-contrast;         /* CSS3 Proposed       */
  width: 100%;
  height: 100%;
}
.image-manipulation-canvas-wrapper .annotation-text-input {
  position: absolute;
  top: var(--top);
  left: var(--left);
  font-family: sans-serif;
  font-size: var(--font-size);
  line-height: var(--font-size);
  height: var(--font-size);
  box-shadow: 0 0 0 var(--border-size) red;
  min-width: 1em;
  max-width: calc(100% - var(--left));
  white-space: nowrap;
  overflow: hidden;
  z-index: 100;
}
.image-manipulation-canvas-wrapper .annotation-text-input.moving {
  pointer-events: none;
}



.image-manipulation-tools-section {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background: var(--color-common-black);
}

.image-manipulation-view-title {
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-large);
  color: var(--color-black);
  padding-bottom: 1rem;
}

.image-manipulation-tools-grid {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius);
}
.image-manipulation-tools-grid hr {
  margin: 0.2rem 0;
}
.image-manipulation-container[data-window-detached="false"] .image-manipulation-tools-section {
  padding-top: 4rem;
}
.image-manipulation-tools-grid_calibration-balloon {
  padding: 1rem;
  text-align: center;
}
.image-manipulation-tools-grid_calibration-balloon > .hint {
  max-width: 12rem;
  text-align: left;
}

.image-manipulation-tools-grid .image-manipulation-tool {
  position: relative;
}
.image-manipulation-tools-grid .image-manipulation-tool_edited-button {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: var(--color-ocra);
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  pointer-events: none;
}

.image-manipulation-tools-container {
  border: var(--border-thick) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .15s linear;
}

.image-manipulation-tools-container:hover {
  background-color: var(--color-xxlight-grey-40);
}

.image-manipulation-tools-container:active {
  background-color: var(--color-xxlight-grey-80);
}

.image-manipulation-tools-container.selected {
  background-color: var(--color-xxlight-grey-80);
  cursor: initial;
}

.image-manipulation-tools-container.selected:hover {
  background-color: var(--color-xxlight-grey-80);
}

.image-manipulation-tools-container.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.image-manipulation-tools-container.disabled:hover {
  background-color: transparent;
}

.image-manipulation-tools-container.active:hover {
  background-color: transparent;
}

.image-manipulation-selection-area {
  position: absolute;
  background-color: var(--color-xxlight-grey-40);
  border: var(--border-thick) var(--color-xxlight-grey);
  pointer-events: none;
}

.image-manipulation-editor-icon {
  position: absolute;
  font-size: var(--font-size-xxlarge);
  transform: translate(-50%,-50%);
  cursor: pointer;
}

.image-manipulation-warnings-wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
}

.image-manipulation-warning {
  background-color: var(--color-ocra);
  color: var(--color-white);
  padding: 1rem;
  width: 100%;
}

.image-manipulation-warning-message > div:nth-child(2) {
  flex-grow: 2;
}
.image-manipulation-warning-message .image-manipulation-warning_short {
  font-weight: bold;
}
.image-manipulation-warning-message .image-manipulation-warning_full {
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}

.image-manipulation-warning-close {
  cursor: pointer;
  font-size: var(--font-size-large);
}

.image-manipulation-warnings_note {
  text-align: center;
  background: var(--color-common-black);
  color: var(--color-ocra);
  padding: 0.5rem;
  width: 100%;
}

.image-manipulation-warning-message {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.image-manipulation-warning .image-manipulation-warning_icon {
  font-size: var(--font-size-xlarge);
}
.image-manipulation-warning .image-manipulation-warning_cta {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.image-manipulation-action-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-manipulation-measurements-section {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 100;
  background: var(--color-xxxlight-grey);
  padding: 1rem;
  border-radius: var(--border-radius-small);
}
.image-manipulation-measurements-section .image-manipulation-measurement > div {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.image-manipulation-measurements-section .image-manipulation-measurement > div label {
  color: var(--color-black);
}
.image-manipulation-measurements-section .image-manipulation-measurement > div:last-of-type {
  border-bottom: none;
}

.image-manipulation-status-bar {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.image-manipulation-status-bar > div {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}




.image-manipulation-measurements-editor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-manipulation-measurements-editor svg {
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.image-manipulation-canvas-container:is([data-mode^="drawing-"], [data-mode="calibrate"]) .image-manipulation-measurements-editor svg {
  cursor: crosshair;
}
.image-manipulation-canvas-container[data-mode="annotate"] .image-manipulation-measurements-editor svg {
  cursor: text;
}

.image-manipulation-measurements-editor svg g {
  cursor: crosshair;
}
.image-manipulation-measurements-editor svg :is(line, ellipse) {
  pointer-events: stroke;
  fill: transparent;
  stroke: yellow;
  stroke-width: 1;
  transition: stroke 0.3s ease-in-out;
}
.image-manipulation-measurements-editor svg g:is(:hover, .selected) :is(line, ellipse) {
  stroke: red;
}
.image-manipulation-measurements-editor svg line.dashed {
  stroke-dasharray: 5;
}
.image-manipulation-measurements-editor svg g.measurement-patch line {
  stroke: transparent;
}
.image-manipulation-measurements-editor svg g rect.patch {
  fill: black;
  stroke: red;
  stroke-width: 0;
  cursor: grab;
}
.image-manipulation-measurements-editor svg g.measurement-text.selected text {
  fill: red;
}
.image-manipulation-measurements-editor svg g.measurement-patch:is(:hover, .selected) line {
  stroke: red;
}
.image-manipulation-measurements-editor svg g.measurement-patch:is(:hover, .selected) rect.patch {
  stroke-width: 1;
}
.image-manipulation-measurements-editor svg g .edit-handle {
  fill: transparent;
  opacity: 0.3;
  transition: fill 0.3s ease-in-out;
}
.image-manipulation-measurements-editor svg g .edit-move {
  stroke: transparent !important;
  stroke-width: 18;
  cursor: grab;
}
.image-manipulation-measurements-editor svg g:hover .edit-handle {
  fill: red;
}
.image-manipulation-measurements-editor svg g.caption,
.image-manipulation-measurements-editor svg g text {
  cursor: grab;
}
