/* search results */
.checklist-item-line-search {
  list-style: none;
  margin: 0;
  color: var(--color-blue);
  padding: 0;
  border: var(--border) transparent;
  border-bottom: var(--border) var(--color-xxlight-grey);
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checklist-item-line-search:last-child {
  border-bottom: 0;
}
.checklist-item-line-search.no-results {
  color: var(--color-xxlight-grey);
  cursor: default;
}

.checklist-item-line-search > div {
  padding: 0.8em 1em;
  display: flex;
  gap: 1rem;
}

.checklist-item-line-search .highlight {
  font-weight: bold;
  border-bottom: var(--border) var(--color-light-grey);
}

.checklist-item-line-search .checklist-item-name {
  flex-grow: 2;
}

.checklist-item-line-search .checklist-item-cta {
  color: var(--color-xlight-grey);
  margin-right: 0.5em;
  font-size: 0.8em;
  white-space: nowrap;
}

@keyframes syndrome-line-hide {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}