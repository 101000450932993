.examination-sharing-flow-sharing-select-container {
  position: fixed;
  z-index: 1019;
  background: var(--color-white);
  top: 0;
  left: 4rem;
  width: calc(100% - 4rem);
  height: 100%;
  overflow: auto;
  font-size: var(--font-size-medium);
}

.examination-sharing-flow-sharing-select-container .examination-live-grid-container .examination-live-slide {
  position: relative;
  display: flex;
  aspect-ratio: 16 / 10;
  border-radius: var(--border-radius);
  background: var(--color-darkcommon-xxxlight-grey);
  transition: background-color .3s ease-in-out;
  border: 0.4rem solid var(--color-darkcommon-xxlight-grey);
  cursor: pointer;
  animation: examination-live-slide-fadein 0.6s ease-in-out forwards;
}

.examination-sharing-flow-sharing-select-container .examination-live-slide.selected {
  border-color: var(--color-blue);
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-parameters-bar {
  background-color: var(--color-blue);
  color: black;
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5rem 0.2rem 1.5rem;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
  font-size: var(--font-size-small);
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-parameters-bar .select-input{
  display: inline-flex;
  margin-left: 0.5rem;
}
.examination-sharing-flow-sharing-select-container .examination-sharing-flow-parameters-bar .select-input > .select-input-current,
.examination-sharing-flow-sharing-select-container .examination-sharing-flow-parameters-bar .select-input > .select-input-current > span.icon.down {
  background: var(--color-grey);
}

.examination-sharing-flow-sharing-select-container .parameters-title {
  font-size: var(--font-size-medium);
  font-weight: 700;
}

.examination-sharing-flow-sharing-select-container .checkbox {
  margin: 0 0.5rem;
}

.examination-sharing-flow-parameters-bar-document-label {
  position: relative;
}

.examination-sharing-flow-parameters-bar-document-label .switch-icon,
.examination-sharing-flow-parameters-bar-document-label .switch-icon svg {
  height: 1.5rem;
  width: 1.5rem;
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-sharing-select {
  display: flex;
  border-color: var(--color-blue);
  border-width: 0.5rem;
  border-style: solid;
  padding: 1rem;
  height: calc(100% - 3.8rem);
}
.examination-sharing-flow-sharing-select-container .examination-sharing-flow-parameters-bar + .examination-sharing-flow-sharing-select {
  height: calc(100% - 6.1rem);
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-sharing-select .selection-status {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-sharing-select .examination-live-grid-container.trimester-ND {
  padding-right: 0;
}

.examination-sharing-flow-sharing-select-container .examination-sharing-flow-sharing-select .examination-sharing-flow-action-bar {
  position: fixed;
  z-index: 1020;
  top: calc(100% - 5.5rem);
  left: calc(100% - 26rem);
  width: 25rem;
  height: 4.5rem;
  padding: 0.75rem;

  overflow: auto;
  display: flex;
  gap: 1rem;

  border-radius: var(--border-radius-small);
  background: var(--color-darkcommon-xxxlight-grey);
}
