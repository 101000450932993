.wl-container {
    margin: 0.5rem;
    padding: 0.5rem;
}

.wl-entry {
    padding: 0.5rem;
    background: var(--color-xxxlight-grey);
    border-radius: var(--border-radius-small);
    margin-top: 1rem;
    align-items: center !important;
}
