.multiselect-container {
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 1;
  min-width: 2em;
  max-width: 100%;
}
.multiselect-container:hover {
  z-index: 100;
}
.multiselect-container.full-width {
  min-width: 100%;
}
.multiselect-container.open {
  z-index: 1000;
}
.multiselect-container > label {
  text-align: center;
  display: block;
  margin-bottom: .3em;
  padding: 0 0.8rem;
}
.multiselect-option.type-recent.heading > .multiselect-label {
  color: var(--color-blue) !important;
  font-weight: bold;
}
.multiselect-option.type-recent.heading > .multiselect-label .selected-children {
  background-color: var(--color-blue);
}
.multiselect-container > .multiselect-current {
  display: block;
  position:relative;
}

.multiselect-container > .multiselect-current.is-active {
  cursor: pointer;
}

.multiselect-container.no-drop-down-icon > .multiselect-current {
  padding-right: 0.8em !important;
}
.multiselect-options .multiselect-search {
  padding: 0.1em 0.5em;
}
.multiselect-options {
  position: absolute;
  z-index: 100001;
  top: 0;
  left: 0;
  height: auto;
  max-height: 0;
  font-size: var(--font-size-small);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
  padding: 0.5em 0;
  background: var(--color-white);
  overflow: auto;
  scrollbar-width: auto;
}
.multiselect-container .multiselect-options {
  overflow: hidden;
  display: none;
}
.multiselect-container.closed .multiselect-options {
  display: none;
}
.multiselect-options label {
  display: block;
  padding: 0.5em 0.8em;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  background: var(--color-white);
}
.multiselect-options .selected-children {
  background: var(--color-black);
  color: var(--color-white);
  margin-left: 0.4em;
  min-width: 1.3em;
  display: inline-block;
  text-align: center;
  border-radius: 1em;
  font-weight: bold;
}
.multiselect-options .multiselect-option {
  display: block;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.3em 0.3em 0.3em 0;
  white-space: nowrap;
}
.multiselect-options .multiselect-option:hover > .multiselect-label {
  cursor: pointer;
  color: var(--color-light-blue);
}
.multiselect-options .multiselect-option:last-of-type {
  border-bottom: 0;
}
.multiselect-options.single .multiselect-option.selected {
  background: var(--color-blue);
  color: var(--color-white);
}
.multiselect-options.single .multiselect-option.selected:hover {
  background: var(--color-light-blue);
}
.multiselect-options.single .multiselect-option.selected:hover .multiselect-label {
  color: var(--color-white);
}
.multiselect-options > .multiselect-option > .multiselect-label {
  padding-left: 1em;
  padding-right: 1em;
}

.multiselect-options .multiselect-option.heading > .nested-tree {
  display: none;
}
.multiselect-options .multiselect-option.heading.open > .nested-tree {
  display: block;
}
.multiselect-options .multiselect-option.heading > .multiselect-label {
  padding: 0.5em 0.5em 0.5em 1.4em;
  background-color: var(--color-white);
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: var(--font-size-small);
  justify-content: space-between;
}
.multiselect-options .multiselect-option.heading.open > .multiselect-label > span {
  rotate: -180deg;
}

.multiselect-options .multiselect-label,
.multiselect-options .multiselect-label > div {
  display: flex;
  gap: 0.4em;
  line-height: 1.4;
  align-items: flex-start;
}

.multiselect-options .nested-tree {
  padding-left: 1em;
  padding-bottom: 1em;
}


.multiselect-container .multiselect-current > .icon {
  display: none;
  position: absolute;
  top: 0.2em;
  left: calc(100% + 0.2em);
  z-index: 30;
  transition: transform 0.3s ease-in-out;
}
.multiselect-container:hover .multiselect-current > .icon,
.multiselect-container.is-editing .multiselect-current > .icon {
  display: block;
}
.multiselect-container.is-editing .multiselect-current > .icon {
  transform: rotate(180deg);
}
.multiselect-container .is-active:hover::after,
.multiselect-container .is-active.is-editing::after {
  width: calc(100% + calc(var(--hover-padding-horizontal) * 2) + 1em) !important;
}

