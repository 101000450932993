.contact-point-card {
    background-color: var(--color-white-40);
    border-radius: var(--border-radius-small);
    padding: 1rem;
    font-size: var(--font-size-medium);
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    position: relative;
    overflow: hidden;
}

.contact-point-card_remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    margin: 0.4rem;
    color: var(--color-black-40);
    cursor: pointer;
}
.contact-point-card_remove:hover {
    color: var(--color-black);
}
.contact-point-card_icon {
    grid-row-end: span 4;
    font-size: var(--font-size-xlarge);
    color: var(--color-white-40);
}
.contact-point-card_external-id {
    font-size: var(--font-size-xsmall);
    display: inline-block;
    color: var(--color-black-40);
    margin-left: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contact-point-card_contacts {
    font-size: var(--font-size-small);
    line-height: 1.4;
    display: flex;
    gap: 1em;
}
.contact-point-card_role {
    font-size: var(--font-size-xsmall);
    text-transform: uppercase;
    color: var(--color-black);
}
