.numeric-input-optional {
  border-radius: var(--border-radius-small);
  padding: 0;
  transition: background-color 0.3s ease-in-out;
  background: var(--color-xxxlight-grey);
  min-height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.numeric-input-optional > label {
  display:block;
  width:100%;
  padding: 0.5em;
}
.numeric-input-optional.open {
  background: var(--color-xxlight-grey);
}
.numeric-input-optional label {
  cursor: pointer;
  padding: 0.5em;
}
.numeric-input-optional label:hover,
.numeric-input-optional.closed label:hover {
  color: var(--color-light-blue);
}
.numeric-input-optional.closed label {
  color: var(--color-dark-grey);
}
.numeric-input-optional label {
  position: relative;
}
.numeric-input-optional .input {
  display: block;
  overflow: visible;
  opacity: 1;
  transition: height .3s ease-in-out, opacity .3s ease-in-out .1s;
}
.numeric-input-optional .input.hidden {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.numeric-input-optional input {
  background: var(--color-white);
  margin-bottom: 0.5em;
}
.numeric-input-optional .numeric-input-textual-value {
  margin-bottom: 0.5em;
}
.numeric-input-optional .numeric-input-textual-value > .value {
  background: var(--color-white);
  color: var(--color-black);
}
