.code-authenticate-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding-top: 4rem;
}

.code-authenticate-wrapper {
  width: 45rem;
  padding-bottom: var(--font-size-xxlarge);
}

.code-authenticate-simple-sentence {
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-size-large);
  color: var(--color-black);
}

.code-authenticate-center-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.code-authenticate-title {
  margin-top: 4rem;
}

.code-authenticate-code-wrapper {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.code-authenticate-code-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
  gap: var(--font-size-medium);
}

.code-authenticate-code-char-input {
  border: solid;
  text-align: center;
  border-radius: var(--border-radius-small);
  border-color: var(--color-xlight-grey);
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  caret-color: transparent;
  height: 3.75rem;
  min-width: 3rem;
  max-width: 3rem;
  line-height: 3.75rem;
  padding: 0;
}

.code-authenticate-code-char-input.filled {
  border-color: var(--color-dark-blue);
}

.code-authenticate-section {
  margin-top: var(--font-size-large);
  margin-bottom: var(--font-size-large);
}

.resend-link {
  color: var(--color-common-blue);
  font-family: Metropolis;
  font-weight: 700;
  text-decoration: underline;
}
