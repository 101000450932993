.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: transparent;
  z-index: 1000;
}
.loader-container.is-loading {
  height: .1rem;
}
.loader-container.is-loading::after {
  content: "";
  display: block;
  position: relative;
  width: 0;
  left: 0;
  height: 100%;
  -webkit-animation: loader-container-animation 3s ease-in-out infinite;
  animation: loader-container-animation 3s ease-in-out infinite;
  background: var(--color-common-blue);
}
@keyframes loader-container-animation {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}
