.sign-line {
  list-style: none;
  margin: 0;
  color: var(--color-blue);
  padding: 0;
  border: var(--border) transparent;
  border-bottom: var(--border) var(--color-xxlight-grey);
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sign-line.interactive {
  cursor: pointer;
}

.sign-line.disabled {
  opacity: 0;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.8s ease-in-out 0.6s, color 0.4s linear;
  pointer-events: none;
}

.sign-line > div {
  padding: 0.8em 1em;
  display: flex;
  gap: 1rem;
}

.sign-line .highlight {
  font-weight: bold;
  border-bottom: var(--border) var(--color-light-grey);
}

.sign-line .sign-name {
  flex-grow: 2;
}
.sign-line .sign-cta {
  color: var(--color-xlight-grey);
  margin-right: 0.5em;
  font-size: 0.8em;
  white-space: nowrap;
}
