.slide-panel-video-container {
  background: var(--color-white);
  height: 100%;
}
.slide-panel-video-container .video-status {
  position: absolute;
  top: 1rem;
}

.slide-panel-video-container .video-status-item {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  white-space: nowrap;
  background: var(--color-blue);
  color: var(--color-white);
  border-radius: var(--border-radius);
  padding: 0.3rem 0.5rem;
  margin-left: 0.5rem;
  display: inline-block;
  vertical-align: center;
}

.slide-panel-dt-video-container .video-status-item .retry-button {
  text-align: center;
  z-index: 1000;
  cursor: pointer;
  display: inline-block;
  margin-left: 0.5rem;
}

.slide-panel-video-container .video-status-item.inprogress {
  background: var(--color-ocra);
  animation: slide-panel-video-status-animation 3s infinite;
}
@keyframes slide-panel-video-status-animation {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.slide-panel-dt-video-container video {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.slide-panel-video-container canvas {
  position: absolute;
  width: 100%;
  height: calc(100% - 3.5rem);
  object-fit: contain;
  background: var(--color-common-black);
}

.slide-panel-video-container > .video-controller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.slide-panel-video-container > .video-controller > .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  font-size: var(--font-size-xxxlarge);
  color: var(--color-common-white);
  transform: translate(-50%, -50%);
  opacity: 1;
  pointer-events: none;
  animation: slide-panel-video-container-icon-fade 0.3s ease-in-out 0.5s forwards;
}
.slide-panel-video-container > .video-controller > .icon.icon-left {
  left: 10%;
}
.slide-panel-video-container > .video-controller > .icon.icon-right {
  left: 90%;
}
@keyframes slide-panel-video-container-icon-fade {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

.slide-panel-video-container > .video-controller .previous-frame,
.slide-panel-video-container > .video-controller .next-frame,
.slide-panel-video-container > .video-controller .toggle-play {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 20%;
  cursor: pointer;
}
.slide-panel-video-container > .video-controller .next-frame {
  left: 80%;
  width: 20%;
}
.slide-panel-video-container > .video-controller .toggle-play {
  left: 20%;
  width: 60%;
}

.slide-panel-video-container .video-controller-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.slide-panel-video-container .video-progress {
  position: relative;
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius-large);
  width: 100%;
  height: 0.8rem;
}
.slide-panel-video-container .video-progress > .video-progress-bar {
  height: 100%;
  background: var(--color-blue);
  border-radius: var(--border-radius-large);
  pointer-events: none;
}
.slide-panel-video-container .video-progress > .video-progress-frame {
  position: absolute;
  top: -50%;
  height: 200%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: background-color 0.3s ease-in-out;
  transform: translateX(-50%);
}
.slide-panel-video-container .video-progress > .video-progress-frame::after {
  content: '';
  display: block;
  aspect-ratio: 1 / 1;
  height: 40%;
  background-color: var(--color-ocra);
  border-radius: 50%;
}
.slide-panel-video-container .video-progress > .video-progress-frame:hover {
  background: var(--color-ocra-40);
}
.slide-panel-video-container .video-progress .video-progress-frame-thumbnail {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 15rem;
  aspect-ratio: 4 / 3;
  transform: translateX(-50%);
  border: var(--border-thick) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  background: var(--color-xxlight-grey);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
}
.slide-panel-video-container .video-progress .video-progress-frame-thumbnail::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(var(--color-common-black), transparent);
  z-index: 5;
}
.slide-panel-video-container .video-progress .video-progress-frame-thumbnail img {
  aspect-ratio: 16 / 10;
  width: 100%;
  object-fit: cover;
}
.slide-panel-video-container .video-progress .video-progress-frame-thumbnail > .header-title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  color: var(--color-common-white);
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  text-shadow: 0 0.1em 0 var(--color-common-black), 0.1em 0 0 var(--color-common-black), 0 -0.1em 0 var(--color-common-black), -0.1em 0 0 var(--color-common-black);
  pointer-events: none;
  z-index: 10;
}
.slide-panel-video-container .video-progress .video-progress-frame-thumbnail > .header-title.unusual {
  color: var(--color-ocra);
}
.slide-panel-video-container .video-progress > .video-progress-frame:hover {
  z-index: 10;
}
.slide-panel-video-container .video-progress > .video-progress-frame:hover .video-progress-frame-thumbnail {
  opacity: 1;
  bottom: 90%;
  pointer-events: all;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  cursor: pointer;
}

