.snack-container {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 10000;
  height: 2.8rem;
  line-height: 2.8rem;
  transition: bottom 0.15s;
}

.snack-container.hide {
  bottom: -2.8rem;
}

.snack-internal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5rem;
  padding-right: 3rem;
}

.snack-internal-container > .icon {
  font-size: var(--font-size-xlarge);
}
.snack-internal-container > .snack-row > .icon {
  font-size: var(--font-size-large);
}

.snack-container.success {/* We take the common color here */
  background-color: var(--color-common-grey);
  color: var(--color-common-blue);
}
.snack-container.info{
  background-color: var(--color-common-grey);
  color: var(--color-common-blue);
}
.snack-container.error{
  background-color: var(--color-light-ocra);
  color: var(--color-xdark-ocra);
}
.snack-container.warning{
  background-color: var(--color-light-ocra);
  color: var(--color-xdark-ocra);
}

.snack-bar {
  height: 0.5rem;
  width: 100%;
}
.snack-bar.success{
  background-color: var(--color-common-blue);
}
.snack-bar.info{
  background-color: var(--color-common-blue);
}
.snack-bar.error {
  background-color: var(--color-xdark-ocra);
}
.snack-bar.warning{
  background-color: var(--color-xdark-ocra);
}

.snack-row {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
