.not-found-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: auto;
  padding-top: 4rem;
}

.not-found-layout {
  width: 45rem;
  padding-bottom: var(--font-size-xxlarge);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.not-found-title {
  text-align: center;
  margin-bottom: var(--font-size-xxlarge);
}

.not-found-desc {
  margin-bottom: 4rem;
}

.not-found-action-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: var(--font-size-large);
  justify-content: space-around;
  align-items: center;
}
