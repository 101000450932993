.diagnostic-panel {
  top: 0;
  left: 0;
  position: absolute;
  min-width: 100%;
  max-width: 100%;
  z-index: 20;
  height: 100%;
  padding: 0;
  transition: left 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
}

.diagnostic-panel .diagnostic-panel-container {
  flex-grow: 1;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 0 1.5rem;
  min-height: 10rem;
  overflow-y: scroll;
}

.diagnostic-panel .diagnostic-panel-container .search-for-sign {
  padding-top: 1rem;
  background: var(--color-white);
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.diagnostic-panel .diagnostic-panel-container .search-for-sign > div:first-child {
  flex-grow: 2;
}
.diagnostic-panel .diagnostic-panel-container .no-results {
  font-size: var(--font-size-small);
  text-align: center;
  color: var(--color-light-grey);
  padding: 1rem;
}

.diagnostic-panel .diagnostic-panel-container .to-check {
  overflow: auto;
  flex-grow: 2;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}

.diagnostic-panel .diagnostic-panel-container h2 {
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--color-xlight-blue);
  border-bottom: var(--border);
  padding-bottom: 0.25rem;
  margin: 0 0 0.5rem;
}

.diagnostic-panel .diagnostic-panel-container h2 span.entity {
  color: var(--color-light-blue);
  cursor: pointer;
  margin: 0 0.5em 0 0;
}
.diagnostic-panel .diagnostic-panel-container h2 span.entity:not(:first-child)::before {
  content: '\e81a'; /* icon-right */
  font-family: 'Fontello';
  margin-right: 0.5em;
  color: var(--color-xlight-blue);
}
.diagnostic-panel .diagnostic-panel-container h2 span.entity:last-of-type {
  margin-right: 0;
}

.diagnostic-panel .diagnostic-panel-container > div > ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.diagnostic-panel .toggle-visibility-button {
  cursor: pointer;
  width: 2rem;
  height: 2.3rem;
  margin-top: 1rem;
  margin-right: -2rem;
  background: var(--color-xxxlight-grey);
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  text-align: center;
  font-size: var(--font-size-large);
  line-height: var(--font-size-xxlarge);
}

.diagnostic-panel .zone-validation {
  margin-top: 0.2rem;
  padding: 0.5rem;
  text-align: right;
  position: absolute;
  z-index: 100;
  bottom: 0.5rem;
  right: 0.5rem;
  background: var(--color-white);
  box-shadow: var(--box-shadow-thin);
  border-radius: var(--border-radius);
  font-size: var(--font-size-medium);
}

.diagnostic-panel .diagnostic-panel-warning {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  text-align: center;
}
.diagnostic-panel .diagnostic-panel-warning .icon {
  margin-right: 0.5em;
}
.diagnostic-panel .diagnostic-panel-warning .diagnostic-panel-warning-alert {
  margin-bottom: 0.5rem;
}
