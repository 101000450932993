.global-search-bar {
  width: 100%;
  max-width: 20rem;
  position: relative;
  transition: max-width 0.3s ease-in-out;
}
.global-search-bar.fullwidth {
  max-width: 100%;
}
.global-search-bar.focus {
  max-width: 40rem;
}
.global-search-bar > ul {
  position: absolute;
  top: calc(100% + 0.1rem);
  left: 0;
  z-index: 5;
  padding: 0;
  box-shadow: var(--common-box-shadow);
  max-height: calc(100svh - 15rem);
  overflow: auto;
  min-width: 100%;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  background: var(--color-white);
}
