.uplink-configuration-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
}

.uplink-configuration-container .uplink-item {
  background: var(--color-xxxlight-grey);
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-small);
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
