.button-add-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.button-add-container > .icon {
  font-size: 1em;
  border: var(--border-thick) var(--color-light-grey);
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.button-add-container:hover > .icon {
  color: var(--color-light-blue);
  border-color: var(--color-light-blue);
}
.button-add-container.active > .icon {
  color: var(--color-black) !important;
  border-color: var(--color-black) !important;
}

.button-add-container label {
  font-size: var(--font-size-small);
  margin: 0 0.7em;
  color: var(--color-xlight-grey);
  width: 6em;
  line-height: 1em;
  text-align: left;
}
