.syndrome-search-bar-container {
  display:block;
  width:100%;
  position:relative;
}
.syndrome-search-bar-container .syndrome-search-bar {
  display:block;
  position:relative;
}
.syndrome-search-bar-container .syndrome-search-bar input {
  width:100%;
}
.syndrome-search-bar-container .syndrome-search-bar small {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  text-align: left;
  margin-left: 2.2rem;
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
}

.syndrome-search-bar-container .syndrome-search-bar-autocomplete {
  position: absolute;
  z-index:10;
  top: 100%;
  left: 0;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  width: 100%;
  text-align: left;
  max-height: 43vh;
  overflow: auto;
  box-shadow: var(--common-box-shadow);
}
.syndrome-search-bar-container .syndrome-search-bar-autocomplete ul {
  list-style: none;
  padding: .5rem 1rem;
}
.syndrome-search-bar-container .syndrome-search-bar-autocomplete > ul > li {
  margin: 0;
  padding: .7em 1rem 1em;
  border-bottom: var(--border) var(--color-xlight-grey);
  cursor:pointer;
}
.syndrome-search-bar-container .syndrome-search-bar-autocomplete > ul > li:hover {
  background:var(--color-xlight-grey);
}
.syndrome-search-bar-container .syndrome-search-bar-autocomplete > ul > li:last-child {
  border-bottom: 0;
}
.syndrome-search-bar-container .syndrome-search-bar-autocomplete .highlight {
  font-weight:bold;
  border-bottom: var(--border-dashed);
}