.switch {
    display:inline-block;
}
.switch ul {
  display:inline-block;
  padding:0;
  list-style:none;
  white-space: nowrap;
  background: var(--color-grey);
  border-radius:var(--border-radius-small);
}
.theme-dark .switch ul {
  background: var(--color-xxlight-grey);
}
.switch ul li {
    display:inline-block;
    margin:0;
    padding:.3em;
    border-radius:var(--border-radius-small);
    line-height:1em;
    cursor:pointer;
    color:var(--color-xxxlight-grey);
    position:relative;
}
.switch ul li.selected {
    background-color:var(--color-blue);
}
.switch .switch-count {
    position: absolute;
    top: -.3em;
    right: -.5em;
    z-index: 1;
    background: var(--color-darkcommon-xxlight-grey);
    border-radius: var(--border-radius-large);
    min-width: 1.4em;
    height: 1.4em;
    font-size: var(--font-size-medium);
    text-align: center;
    padding: 0 0.2em;
}
.switch .selected .switch-count {
    background-color:var(--color-blue);
}

.switch-count-changed {
  background: var(--color-ocra);
  border-radius: var(--border-radius-large);
  position: absolute;
  top: -.3em;
  right: -.5em;
  min-width: 1.4em;
  height: 1.4em;
  z-index: 10;
  font-size: var(--font-size-medium);
  text-align: center;
  padding: 0 0.2em;
  animation: switch-count-blink 5s forwards;
}

@keyframes switch-count-blink {
  0% {opacity: 0;}
  10% {opacity: 1; outline: 0 solid var(--color-ocra);}
  30% {outline: 0.3rem solid var(--color-ocra);}
  45% {outline: 0 solid var(--color-ocra);}
  60% {outline: 0.3rem solid var(--color-ocra);}
  75% {opacity: 1; outline: 0 solid var(--color-ocra);}
  100% {opacity: 0;}
}