.sign-search-bar {
  position: relative;
  font-size: 1rem;
}
.sign-search-bar span.icon {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: var(--color-xlight-grey);
}
.sign-search-bar span.icon.cancel {
  left: auto;
  right: 0.5rem;
  cursor: pointer;
}
.sign-search-bar span.icon.cancel {
  color: var(--color-light-grey);
}
.sign-search-bar input {
  margin: 0;
  width: 100%;
  padding: .3em .3em .3em 2em;
  font-size: 1em;
}
.sign-search-bar .hint {
  font-size: var(--font-size-xsmall);
  position: absolute;
  bottom: -1.2em;
  left: 3em;
  color: var(--color-xxlight-grey);
  background: var(--color-white);
  line-height: var(--font-size-xsmall);
  padding: 0 0.5em;
}

.sign-search-bar .sign-search-bar-notification {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  background: var(--color-blue);
  border-radius: var(--border-radius-small);
  color: var(--color-white);
  animation: sign-search-bar-notification 3s forwards;
}

@keyframes sign-search-bar-notification {
  0% { opacity: 0 }
  10% { opacity: 1 }
  70% { opacity: 1 }
  100% { opacity: 0 }
}
