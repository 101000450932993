.exam-report-coding {
  background: var(--color-xxlight-grey-40);
  box-shadow: 0 20rem 0 0 var(--color-xxlight-grey-40);
  left: 50%;
  position: relative;
  translate: -50svw 0;
  width: 100svw;
  max-width: 100svw;
  padding: 5rem calc(50svw - 50%);
}
.exam-report-coding_heading {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.exam-report-coding_heading h1 {
  min-width: 10rem;
}

.exam-report-coding-section {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex: 1 5;
}
.exam-report-coding-section_title {
  min-width: 10rem;
  max-width: 10rem;
}
.exam-report-coding-section_title > button {
  margin-top: 0.5rem;
}
.exam-report-coding-section_table {
  flex-grow: 2;
}
.exam-report-coding-section_suggestions {
  margin: 1rem 0 0 2rem;
  line-height: 1.2;
}

.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion {
  padding: 0.4rem 0.2rem;
  font-size: var(--font-size-small);
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion:hover {
  color: var(--color-black);
}
.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion > .code {
  min-width: 4rem;
}
.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion > .meta {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
}
.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion > .meta > .description {
  margin-right: 1rem;
}
.exam-report-coding-section_suggestions .exam-report-coding-section_suggestion > .meta > .suggested {
  color: var(--color-xlight-grey);
}

