.date-input {
  white-space: nowrap;
  display: inline-block;
  align-items: center;
}
.date-input input {
  width: 8em !important;
  text-align: center;
  margin: 0 0.2em;
  padding-left: 0;
  padding-right: 0;
}
