.nd-examination-toolbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nd-examination-toolbar-container > .message {
  color: var(--color-black);
  font-size: var(--font-size-large);
}

.nd-examination-toolbar-container > .buttons {
  display: flex;
  align-items: center;
  color: var(--color-common);
  font-size: var(--font-size-small);
  cursor: pointer;
}

.nd-examination-toolbar-container > .buttons > .action-btn-group {
  border: var(--border);
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.nd-examination-toolbar-container
  > .buttons
  > .action-btn-group
  > .action-settings {
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
