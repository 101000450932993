.alert {
  font-size: var(--font-size-medium);
  color: var(--color-common-blue);
  padding: 0 0.2em;
  overflow-wrap: break-word;
  gap: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  margin: 0.3em 0 0;
}

.alert > .close {
  font-size: var(--font-size-large);
  cursor: pointer;
}
.alert > .close:hover {
  opacity: 0.5;
}

