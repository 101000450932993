.select-input-optional {
  border-radius: var(--border-radius-small);
  padding: 0 0.5em;
  background:var(--color-xxxlight-grey);
  transition: background-color 0.3s ease-in-out;
  min-height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.select-input-optional.open {
  background: var(--color-xxlight-grey);
}
.select-input-optional > label {
  cursor: pointer;
  padding: 0.5em;
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
}
.select-input-optional.closed label {
  color: var(--color-dark-grey);
}
.select-input-optional label:hover,
.select-input-optional.closed label:hover {
  color: var(--color-light-blue);
}

.select-input-optional .input {
  display: block;
  overflow: visible;
  opacity: 1;
  transition: height .3s ease-in-out, opacity .3s ease-in-out .1s;
}
.select-input-optional .input.hidden {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.select-input-optional .select-input {
  margin-bottom: 0.5em;
}
.select-input-optional .select-input {
  width: 100%;
}
.select-input-optional .select-input .select-input-current,
.select-input-optional .select-input .options .option label {
  text-align:center;
}
