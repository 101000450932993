.slide-star-button-container {
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-large);
  display: flex;
  flex-direction: column;
}

.slide-star-button-container div {
  opacity: 0.4;
}

.slide-star-button-container .selected {
  opacity: 1;
}
