.biometry-wrapper {
  --stroke-width: 0.3;
  font-size: 6px;
  border-radius: var(--border-radius);
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.biometry-wrapper.not-compatible {
  font-size: var(--font-size-small);
  background: var(--color-white);
}
.biometry-wrapper svg {
  height: 100%;
  width: 100%;
  display: block;
}
.biometry-wrapper svg .background {
  fill: var(--color-blue-40);
}
.biometry-wrapper svg .x-axis text,
.biometry-wrapper svg .y-axis text {
  font-size: 0.8em;
  fill: var(--color-blue);
}
.biometry-wrapper svg .x-axis text.label,
.biometry-wrapper svg .y-axis text.label {
  font-size: 0.6em;
}
.biometry-wrapper svg .x-axis line,
.biometry-wrapper svg .y-axis line {
  stroke: var(--color-blue);
  stroke-width: var(--stroke-width);
}
.biometry-wrapper svg .percentiles path {
  fill: transparent;
  stroke: var(--color-blue);
  stroke-width: 0.25;
  stroke-dasharray: 0.5 1.5;
}
.biometry-wrapper svg .percentiles g:not(.first, .last, .half) text {
  display: none;
}
.biometry-wrapper svg .percentiles g.first path,
.biometry-wrapper svg .percentiles g.half path,
.biometry-wrapper svg .percentiles g.last path {
  stroke: var(--color-blue);
  stroke-dasharray: 0;
}
.biometry-wrapper svg .percentiles text {
  font-size: 0.8em;
  fill: var(--color-blue);
}
.biometry-wrapper svg .percentiles text .unit {
  font-size: 0.65em;
}
.biometry-wrapper svg .measurements .point path {
  fill: var(--color-blue);
  stroke-width: 0;
  stroke: transparent;
}
.biometry-wrapper svg.at-risk .measurements .point path {
  fill: var(--color-ocra);
}
.biometry-wrapper svg .measurements .point circle {
  fill: var(--color-light-blue);
  stroke-width: var(--stroke-width);
  stroke: var(--color-white);
}
.biometry-wrapper svg .measurements .point:last-of-type circle {
  fill: var(--color-blue);
}
.biometry-wrapper svg .measurements .point line {
  stroke: var(--color-light-blue);
  stroke-width: var(--stroke-width);
  stroke-dasharray: 1;
  display: none;
}
.biometry-wrapper svg .measurements .point text {
  font-size: 1em;
  fill: var(--color-black);
  stroke-width: 1;
  stroke: var(--color-white);
  display: none;
  pointer-events: none;
}
.biometry-wrapper svg .measurements .point text .x-axis-value {
  font-weight: bold;
  font-size: 0.8em;
}
.biometry-wrapper svg .measurements .point:hover circle {
  stroke: var(--color-light-blue);
}
.biometry-wrapper svg .measurements .point:hover line,
.biometry-wrapper svg .measurements .point:hover text {
  display: block;
}
.biometry-wrapper svg .measurements path {
  fill: transparent;
  stroke: var(--color-light-blue);
  stroke-width: 0.6;
  stroke-dasharray: 1;
}
