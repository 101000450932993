.examination-sharing-list-widget {
    position: absolute;
    width: 25.625rem;
    border: var(--border) var(--color-blue);
    top: 1.75rem;
    border-radius: var(--border-radius-small);
    z-index: 100;
    background-color: var(--color-white);
}

.examination-sharing-list-widget-title {
    color: var(--color-black);
    text-transform: uppercase;
    padding: 0.75rem 1rem;
    border-bottom: var(--border) var(--color-blue);
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-family: Metropolis;
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: 400;
}

.examination-sharing-list-widget-list-item.add-document-label {
    display: block;
}

.examination-sharing-list-widget-list-item.add-document-label a {
    text-decoration: underline;
}

.examination-sharing-list-widget-list-item {
    font-size: var(--font-size-small);
    line-height: var(--font-size-large);
    color: var(--color-blue);
    padding: 0.5rem 1rem;
    border-bottom: var(--border) var(--color-blue);
    display: flex;
    align-items: flex-start;
    font-family: Metropolis;
    gap: 0.25rem;
}

.examination-sharing-list-widget-list-item span:not(.icon) {
    flex-grow: 1;
    word-break: break-word;
}

.examination-sharing-list-widget-upload-button-box {
    padding: 0.5rem 1rem;
}
