.preferences-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
}

.preferences-container .gapped {
  display: flex;
  gap: 1rem;
}

.preferences-container .error {
  color: var(--color-red);
}

.preferences-container .success {
  color: var(--color-green);
}