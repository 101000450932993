.toggle-wrapper {
  cursor: pointer;
  width: 2.2rem;
  height: 1.2rem;
  padding: 0.1rem;
  background-color: var(--color-xlight-grey);
  border-radius: 1.2rem;
  transition: padding-left 0.15s;
}

.toggle-wrapper.on {
  padding-left: 1.1rem;
}

.toggle-wrapper.disabled {
  opacity: 0.6;
}

.toggle-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: var(--color-xxxlight-grey);
  color: #00000000;
  transition: background-color 0.15s, color 0.15s;
  line-height: 1;
}

.toggle-dot.on {
  background-color: var(--color-ocra);
  color: var(--color-white);
}
