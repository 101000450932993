.custom-medical-history-container {
  display: flex;
  justify-content: center;
}

.custom-medical-history-header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
}

.custom-medical-history-header h2 {
  margin: auto;
}

.custom-medical-history-container .exam-report-template {
  padding: 3rem 2rem 3rem 6rem;
}
.custom-medical-history-container .exam-report-frozen-warning {
  z-index: 100;
}

.custom-medical-history-section {
  padding-top: 3rem;
}
