.medication-line {
  margin: 0 0 2rem 0;
}
.medication-line .medication-line-name {
  display: flex;
  align-items: center;
  margin-bottom: 0.3em;
}
.medication-line .medication-line-name::after {
  content: '';
  flex-grow: 2;
  border-top: var(--border) var(--color-xxlight-grey);
  margin-left: 1em;
}
.medication-line .medication-line-name .medication-is-teratogenic {
  font-size: 0.7em;
  line-height: 1.2;
  color: var(--color-white);
  background-color: var(--color-black);
  border-radius: var(--border-radius-large);
  margin-left: 1em;
  padding: 0.2em 0.8em;
}

.medication-line .medication-line-details {
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}
.medication-line .medication-line-molecules {
  color: var(--color-grey);
  margin: 0.3rem 0;
}
.medication-line .medication-line-molecules ul {
  padding: 0;
  list-style: none;
}
.medication-line .medication-line-molecules ul li {
  margin: 0;
  display: inline-block;
  white-space: nowrap;
}
.medication-line .medication-line-molecules ul li:not(:last-child):after {
  content: ',';
  margin-right: 0.3em;
}