.configuration-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
}

.site-user-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.manager-info {
  text-align: center;
  color: var(--color-light-grey);
  font-size: var(--font-size-xsmall);
}

.mode-info-checked {
  color: var(--color-ocra);
}

.mode-info-user {
  color: var(--color-black);
}

.mode-info-site {
  color: var(--color-black);
}

.mode-info {
  color: var(--color-light-grey);
  font-size: var(--font-size-xsmall);
}

.filter-list-container {
  display: flex;
  flex-direction: column;
}
.live-checkbox{
  display: flex;
  flex-direction: column;
  margin: auto 0;
}