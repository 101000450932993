.input-password-wrapper {
  width: 100%;
  min-height: 3rem;
  max-height: 3rem;
  position: relative;
}

.input-password-wrapper > input {
  height: 3rem;
  padding: 0.75rem;
  padding-right: calc(1.5rem + 0.75rem);
}

.input-password-icon {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0.75rem;
}
