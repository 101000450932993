.success-pages-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.left-logo {
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--font-size-large);
}

.left-logo > img {
  width: 7.5rem;
}

.success-pages-check-icon {
  font-size: 6rem;
}

.success-pages-exclamation {
  width: 45rem;
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-xxlarge);
  line-height: var(--font-size-xxlarge);
  text-align: center;
  margin-top: var(--font-size-xxlarge);
}

.success-pages-button {
  margin-top: var(--font-size-xxlarge);
}
