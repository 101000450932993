@font-face {
  font-family: 'Fontello Simulator';
  src: url('fontello.woff2') format('woff2'),
       url('fontello.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon-simulator {
  font-family: "Fontello Simulator" !important;
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-keyboard-left:before { content: '\e856'; } /* '' */
.icon-keyboard-enter:before { content: '\e857'; } /* '' */
.icon-keyboard-right:before { content: '\e858'; } /* '' */
.icon-keyboard-space:before { content: '\e859'; } /* '' */
.icon-keyboard-esc:before { content: '\e85a'; } /* '' */
