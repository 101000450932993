.dx-checklist-syndrome-details-container {
  padding: 2rem 2rem 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dx-checklist-summary-container .dx-checklist-summary-syndrome-header {
  display: flex;
  align-items: start;
  padding-bottom: .5rem;
  font-size: var(--font-size-large);
}
.dx-checklist-summary-container .close-syndrome {
  font-size: var(--font-size-xlarge);
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  cursor: pointer;
}
.dx-checklist-summary-container .close-syndrome:hover .icon {
  color: var(--color-black);
  border-color: var(--color-black);
}
.dx-checklist-summary-container .dx-checklist-summary-syndrome-name {
  flex-grow: 2;
  text-align: center;
}
.dx-checklist-summary-container .dx-checklist-summary-syndrome-name small {
  font-size: var(--font-size-medium);
  color: var(--color-grey);
}
.dx-checklist-summary-container .dx-checklist-summary-syndrome-cta > span {
  display: inline-block;
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  border: var(--border) var(--color-xxlight-grey);
  padding: 0.5rem;
  border-radius: var(--border-radius-large);
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  cursor: pointer;
}
.dx-checklist-summary-container .dx-checklist-summary-syndrome-cta > span:hover {
  color: var(--color-black);
  border-color: var(--color-black);
}
.dx-checklist-summary-container .dx-checklist-summary-syndrome-cta > span.active .icon {
  margin-right: 0.5em;
}

.proximity-score,
.syndrome-prevalence {
  text-align: center;
  background: var(--color-xxlight-grey-40);
  padding: 0.5rem;
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-small);
}

.dx-checklist-summary-syndrome-tabs {
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.dx-checklist-summary-syndrome-tabs ul {
  text-align: center;
  list-style: none;
  padding: 0;
  font-size: var(--font-size-small);
}
.dx-checklist-summary-syndrome-tabs li {
  display: inline-block;
  margin: 0 1em -.05em;
  white-space: nowrap;
  cursor: pointer;
}
.dx-checklist-summary-syndrome-tabs li.inactive {
  color: var(--color-xlight-grey);
}
.dx-checklist-summary-syndrome-tabs li.selected {
  color: var(--color-black);
  border-bottom: var(--border-thick);
}

.dx-checklist-summary-container .dx-checklist-summary-syndrome-body {
  flex-grow: 2;
  overflow: auto;
}

.dx-checklist-summary-syndrome-tab {
  padding: 1rem 0;
  display: none;
}
.dx-checklist-summary-syndrome-tab.visible {
  display: block;
}
.dx-checklist-summary-syndrome-tab h3 {
  font-size: var(--font-size-medium);
  margin: 0.5rem 0;
}
.dx-checklist-summary-syndrome-tab h3.sticky {
  position: sticky;
  top: 1rem;
  word-wrap: break-word;
}
.dx-checklist-summary-syndrome-tab h3 small {
  display: block;
  font-weight: normal;
  margin: 0.3rem 0;
}
.dx-checklist-summary-syndrome-tab h3 small.malformation-prevalence {
  font-size: var(--font-size-xsmall);
}

.dx-checklist-summary-syndrome-tab .row {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.dx-checklist-summary-syndrome-empty-tab {
  text-align: center;
  color: var(--color-xxlight-grey);
  padding: 2rem;
  font-size: var(--font-size-small);
}

.dx-checklist-summary-syndrome-tab .proximity-score .score-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.3em;
  min-width: 4em;
  display: inline-block;
  margin-left: 0.8em;
}
.dx-checklist-summary-syndrome-tab .proximity-score .score-value small {
  color: var(--color-light-grey);
}

.dx-checklist-summary-syndrome-tab .risk-factor {
  padding:0.4rem 0;
}
.dx-checklist-summary-syndrome-tab .risk-factor.yes {
  color: var(--color-ocra);
}
.dx-checklist-summary-syndrome-tab .risk-factor .label {
  display: inline-flex;
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
  min-width: 10rem;
}
.dx-checklist-summary-syndrome-tab .risk-factor .label::after {
  content: "";
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}
.dx-checklist-summary-syndrome-tab .risk-factor .value {
  display: inline-block;
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 .5em;
  margin-left: .5em;
}

.dx-checklist-summary-syndrome-tab .diagnosticable-by {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dx-checklist-summary-syndrome-tab .diagnosticable-by li {
  list-style: none;
  background: var(--color-darkcommon-xxlight-grey);
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-small);
  display: inline-block;
  white-space: nowrap;
}

.dx-checklist-summary-syndrome-tab .to-check-in-the-future li {
  margin: 0;
  padding: 0.4rem;
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.dx-checklist-summary-syndrome-tab .to-check-in-the-future {
  list-style: none;
  padding: 0;
  margin: 0;
}

