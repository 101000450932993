.appointment-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.appointment-time {
  padding: 1rem;
  text-align: center;
}

.appointment-details-container {
  width: 100%;
  border-radius: var(--border-radius-small);
  border: var(--border) var(--color-light-grey);
}

.appointment-details-container > .header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appointment-details-container > .header > .patient-details {
  display: flex;
}

.appointment-details-container > .header > .patient-details > .name,
.appointment-details-container > .header > .patient-details > .icon {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.appointment-details-container > .header > .patient-details > .age {
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: var(--border);
}

.appointment-details-container > .header > .patient-details > .status {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0rem 0.5rem;
  margin-left: 0.5rem;
}

.appointment-details-container > .header > .cta {
}

.appointment-details-container > .current-exam {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.appointment-details-container > .current-exam > .exam-type {
  padding: 0.25rem 0.5rem;
  border: var(--border);
  border-radius: var(--border-radius-small);
  font-weight: bold;
  margin-right: 0.5rem;
}

.appointment-details-container > .current-exam > .exam-name {
  font-weight: bold;
  margin-right: 0.5rem;
  /* border-right: var(--border); */
}

.appointment-details-container > .current-exam > .exam-status {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0rem 0.5rem;
}

.appointment-details-container > .history {
  padding: 1rem;
  display: flex;
}

.appointment-details-container > .history > .medical-history {
  width: 33%;
  border-right: var(--border) var(--color-light-grey);
}

.appointment-details-container > .history > .medical-history > .header {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.appointment-details-container > .history > .previous-exams {
  width: 33%;
  margin-left: 1rem;
}

.appointment-details-container > .history > .previous-exams > .header {
  font-weight: bold;
}

.appointment-details-container > .metadata {
  margin: 1rem;
  padding-top: 1rem;
  border-top: var(--border) var(--color-light-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.6rem;
  line-height: 1;
}

.appointment-details-container > .metadata > .metadata-item {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.appointment-details-container > .metadata > .metadata-item > .label {
  margin-right: 0.25rem;
  font-weight: bold;
}
