.tabs-container > ul {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.tabs-container.fullwidth > ul > li {
  flex-grow: 2;
}
.tabs-container > ul > li {
  display: flex;
  align-items: center;
  gap: 0.3em;
  padding: 0.5em 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
  cursor: pointer;
  color: var(--color-blue);
  line-height: 1;
}
.tabs-container > ul > li:hover {
  color: var(--color-light-blue);
}
.tabs-container > ul > li.selected {
  color: var(--color-black);
  border-bottom: var(--border-xthick) var(--color-black);
}
.tabs-container > ul > li[data-irrelevant=true]:not(.selected) {
  opacity: 0.3;
}
.tabs-container > ul > li .tabs-counter {
  font-size: 0.6em;
  line-height: 1;
  background: var(--color-ocra);
  color: var(--color-white);
  border-radius: 1em;
  padding: 0.2em;
  min-width: 1.5em;
  text-align: center;
  display: block;
}