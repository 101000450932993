.pagination-bar {
  height: 4rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-xxlight-grey-80);
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 1rem;
}

.pagination-bar > div {
  margin-right: 1rem;
}

.pagination-bar .button {
  opacity: 0.7;
  margin-left: 0.5rem;
}

.pagination-bar .button[disabled] {
  opacity: 0.2;
}
