.examination-sharing-upload-file-popup-background-container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: var(--color-white);
    opacity: 0.8;
}

.examination-sharing-upload-file-popup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11000;
}

.examination-sharing-upload-file-popup {
    position: relative;
    z-index: 10000;
    width: 43.875rem;
    padding: 2rem 2rem 3.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--border-radius-small);
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
}

.examination-review-sharing-preview-popup-close-button {
    z-index: 10001;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: var(--font-size-xlarge);
    cursor: pointer;
}

.examination-review-sharing-preview-popup-close-button svg {
    fill: var(--color-black);
}

.examination-sharing-upload-file-popup-title {
    padding: 1rem 0;
    color: var(--color-black);
    font-size: var(--font-size-xlarge);
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin: 0;
}

.examination-sharing-upload-file-popup-drag-and-drop-box-label {
    color: var(--color-xlight-blue);
    font-size: var(--font-size-medium);
    font-weight: 400;
    margin: 0;
    padding: 0.5rem 0;
}

.examination-sharing-upload-file-popup-drag-and-drop-box-label span {
    text-decoration: underline;
}

.examination-sharing-upload-file-popup-drag-and-drop-box {
    width: 100%;
    border-radius: 0.5rem;
    border: var(--border-dashed) var(--color-light-grey);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1rem;
    gap: 0.625rem;
    position: relative;
}

.examination-sharing-upload-file-popup-drag-and-drop-box.drag-on {
    background-color: var(--color-xxlight-grey);
}

.examination-sharing-upload-file-popup-drag-and-drop-box .upload-file-icon {
    height: min-content;
}

.examination-sharing-upload-file-popup-drag-and-drop-box .upload-file-icon svg {
    height: 2.5rem;
    width: 2.5rem;
    display: block;
}

.examination-sharing-upload-file-popup-drag-and-drop-box .upload-file-icon svg path {
    fill: var(--color-xlight-blue);
}

.examination-sharing-upload-file-popup-upload-file-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 10.5rem;
    overflow: auto;
    scrollbar-width: auto;
}

.examination-sharing-upload-file-popup-upload-file-list.padding-right {
    padding-right: 0.5rem;
}

/* scroll width */
.examination-sharing-upload-file-popup-upload-file-list::-webkit-scrollbar {
    width: 0.5rem;
    display: block;
}

/* scroll track */
.examination-sharing-upload-file-popup-upload-file-list::-webkit-scrollbar-track {
    background: var(--color-white);
}

/* scroll handle */
.examination-sharing-upload-file-popup-upload-file-list::-webkit-scrollbar-thumb {
    background: var(--color-xxlight-grey);
    border-radius: 0.25rem;
}

/* scroll handle on hover */
.examination-sharing-upload-file-popup-upload-file-list::-webkit-scrollbar-thumb:hover {
    background: var(--color-xxlight-grey);
}

.examination-sharing-upload-file-popup-upload-file-list-label {
    color: var(--color-black);
    font-size: 0.75rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin: 0;
    padding: 2rem 0 0.5rem 0;
    width: 100%;
}

.examination-sharing-upload-file-popup-actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding-top: 2rem;
}

.examination-sharing-upload-file-popup-drag-and-drop-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 10;
    cursor: pointer;
}

.examination-sharing-upload-file-popup-error-message-item {
    position: relative;
    width: 100%;
}

.examination-sharing-upload-file-popup-error-message-item.item-1 {
    height: 1.5rem;
}

.examination-sharing-upload-file-popup-error-message-item.item-2 {
    height: 1.5rem;
}

.examination-sharing-upload-file-popup-error-message {
    position: absolute;
    top: 0;
    font-size: 1rem;
    color: #CE6361;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.examination-sharing-upload-file-popup-error-message.item-1 {
    top: 1.5rem;
}

.examination-sharing-upload-file-popup-error-message.item-2 {
    top: 3rem;
}

.examination-sharing-upload-file-popup-error-message.item-3 {
    top: 4.5rem;
}

.examination-sharing-upload-file-popup-error-message.item-3 {
    top: 6.0rem;
}

.examination-sharing-upload-file-popup-attach-file {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.75rem;
    border-radius: var(--border-radius-small);
    border: var(--border) var(--color-light-grey);
}

.examination-sharing-upload-file-popup-attach-file-name {
    flex-grow: 1;
    font-size: var(--font-size-medium);
    font-weight: 400;
    color: var(--color-xlight-blue);
    padding: 0;
    padding-left: 0.5rem;
    margin: 0;
}

.examination-sharing-upload-file-popup-attach-file-name svg {
    fill: var(--color-xlight-blue) !important;
}

.examination-sharing-upload-file-popup-attach-file .delete-icon {
    position: relative;
    z-index: 20;
    cursor: pointer;
}
